import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import axios from "axios";

import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import toast from "../../errors/toast";
import usePlans from "../../hooks/usePlans";

// ============================================================
// Estilos
// ============================================================
const useStyles = makeStyles((theme) => ({
  // --------------------------
  // Papel principal
  // --------------------------
  mainPaper: {
    margin: "20px auto",
    maxWidth: 1200,
    padding: theme.spacing(4),
    borderRadius: 13,
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    fontFamily: "'Inter', Arial, sans-serif",
    color: "#333",
  },

  // --------------------------
  // Título principal
  // --------------------------
  header: {
    textAlign: "center",
    color: "#5e35b1",
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    fontSize: "1.8rem",
  },
  contentText: {
    textAlign: "center",
    color: "#555",
    fontSize: "1rem",
    marginBottom: theme.spacing(4),
  },

  // --------------------------
  // Blocos de seção
  // --------------------------
  sectionPaper: {
    backgroundColor: "#f9f9fe",
    borderRadius: 12,
    border: "1px solid #eceaf3",
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    color: "#5e35b1",
    fontSize: "1.4rem",
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  // --------------------------
  // Subtítulos, listas, blocos
  // --------------------------
  instructionsList: {
    marginLeft: theme.spacing(3),
    "& li": {
      marginBottom: theme.spacing(1),
      lineHeight: 1.5,
    },
    "& ul": {
      marginLeft: theme.spacing(3),
      listStyleType: "disc",
    },
  },
  infoTitle: {
    fontWeight: 700,
    color: "#5e35b1",
    marginBottom: theme.spacing(1.5),
    fontSize: "1rem",
  },
  infoTitle2: {
    fontWeight: 700,
    color: "#333",
    marginBottom: theme.spacing(1.5),
    fontSize: "1rem",
  },
  testTitle: {
    fontWeight: 600,
    color: "#333",
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
  },
  infoList: {
    fontSize: "0.95rem",
    lineHeight: 1.5,
  },

  // --------------------------
  // Destaques de código e método
  // --------------------------
  codeBlock: {
    backgroundColor: "#F9FAFB", // fundo cinza claro
    padding: theme.spacing(1),
    borderRadius: 10, // bordas arredondadas
    border: "1px solid #dcdcdc", // borda de contorno cinza
    marginTop: theme.spacing(1),
    fontFamily: "Menlo, Monaco, 'Courier New', monospace", // fonte do código
    fontSize: "0.9rem",
    whiteSpace: "pre-wrap", // para quebras de linha no código
  },
  methodTag: {
    display: "inline-block",
    backgroundColor: "#f3e5f5",
    color: "#5e35b1",
    fontWeight: 700,
    padding: "2px 10px",
    borderRadius: 16,
    fontSize: "0.8rem",
    marginLeft: theme.spacing(1),
  },

  // --------------------------
  // Formulários
  // --------------------------
  formContainer: {
    marginTop: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1, 0),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // bordas arredondadas
      backgroundColor: "#fff", // cor branca no fundo
      "&.Mui-focused fieldset": {
        borderColor: "#5e35b1", // cor de foco roxa
      },
    },
    "& label.Mui-focused": {
      color: "#5e35b1", // cor do label no foco
    },
  },
  button: {
    marginTop: theme.spacing(2),
    backgroundColor: "#5e35b1", // cor de fundo roxa
    color: "#fff",
    textTransform: "none", // sem uppercase
    fontWeight: 700,
    fontSize: "0.95rem",
    borderRadius: 8, // bordas arredondadas
    padding: theme.spacing(1.2, 2),
    minWidth: 120,
    "&:hover": {
      backgroundColor: "#4527a0", // cor roxa mais escura no hover
    },
  },
  textRight: {
    textAlign: "right",
  },
  fileInput: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1, 0),
    "& input[type='file']": {
      display: "none", // esconde o input de arquivo real
    },
    "& label": {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(1),
      borderRadius: 8,
      backgroundColor: "#fff",
      border: "1px solid #dcdcdc",
      width: "100%",
      fontSize: "0.875rem", // ajustar o tamanho da fonte
    },
  },
}));

// ============================================================
// Componente principal
// ============================================================
const MessagesAPI = () => {
  const classes = useStyles();
  const history = useHistory();

  const [formMessageTextData] = useState({ token: "", number: "", body: "" });
  const [formMessageMediaData] = useState({ token: "", number: "", medias: "" });
  const [file, setFile] = useState({});

  const { getPlanCompany } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useExternalApi) {
        toast.error("Esta empresa não possui permissão para acessar essa página! Redirecionando...");
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    }
    fetchData();
  }, [getPlanCompany, history]);

  const getEndpoint = () => `${process.env.REACT_APP_BACKEND_URL}/api/messages/send`;

  // --------------------------------------------------
  // Função de envio de texto
  // --------------------------------------------------
  const handleSendTextMessage = async (values) => {
    const { number, body } = values;
    const data = { number, body };

    try {
      await axios.post(getEndpoint(), data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${values.token}`,
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  // --------------------------------------------------
  // Função de envio de mídia
  // --------------------------------------------------
  const handleSendMediaMessage = async (values) => {
    const firstFile = file[0];
    const data = new FormData();
    data.append("number", values.number);
    data.append("medias", firstFile);

    try {
      await axios.post(getEndpoint(), data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${values.token}`,
        },
      });
      toast.success("Mensagem enviada com sucesso");
    } catch (err) {
      toastError(err);
    }
  };

  // -------------------------------------
  // Formulário de Texto
  // -------------------------------------
  const renderFormMessageText = () => (
    <Formik
      initialValues={formMessageTextData}
      onSubmit={async (values, actions) => {
        await handleSendTextMessage(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="token"
                label={i18n.t("messagesAPI.textMessage.token")}
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.textField}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="number"
                label={i18n.t("messagesAPI.textMessage.number")}
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.textField}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="body"
                label={i18n.t("messagesAPI.textMessage.body")}
                variant="outlined"
                margin="dense"
                fullWidth
                multiline
                rows={3}
                className={classes.textField}
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                className={classes.button}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  // -------------------------------------
  // Formulário de Mídia
  // -------------------------------------
  const renderFormMessageMedia = () => (
    <Formik
      initialValues={formMessageMediaData}
      onSubmit={async (values, actions) => {
        await handleSendMediaMessage(values);
        actions.setSubmitting(false);
        actions.resetForm();
        setFile({});
      }}
    >
      {({ isSubmitting }) => (
        <Form className={classes.formContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="token"
                label={i18n.t("messagesAPI.mediaMessage.token")}
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.textField}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="number"
                label={i18n.t("messagesAPI.mediaMessage.number")}
                variant="outlined"
                margin="dense"
                fullWidth
                className={classes.textField}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fileInput}>
                <input
                  type="file"
                  id="file-upload"
                  onChange={(e) => setFile(e.target.files)}
                  style={{ display: "none" }}
                />
                <label htmlFor="file-upload">
                  Escolher arquivo
                  <span>
                    {file && file[0] ? file[0].name : "Nenhum arquivo escolhido"}
                  </span>
                </label>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.textRight}>
              <Button
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                className={classes.button}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  // -------------------------------------
  // Render principal
  // -------------------------------------
  return (
    <Paper className={classes.mainPaper}>
      {/* Cabeçalho */}
      <Typography variant="h5" className={classes.header}>
        Documentação da API de Envio de Mensagens da WhatsNeed
      </Typography>

      {/* Texto introdutório */}
      <Typography className={classes.contentText}>
        Bem-vindo à seção de documentação da API de envio de mensagens da WhatsNeed.
        Aqui você encontrará todas as informações necessárias para integrar e utilizar
        nossa API de forma eficiente.
      </Typography>

      {/* 1. Instruções Importantes */}
      <Paper elevation={0} className={classes.sectionPaper}>
        <Typography className={classes.sectionTitle}>
          Instruções Importantes
        </Typography>
        <ul className={classes.instructionsList}>
          <li>
            <strong>Cadastro do Token:</strong> Para habilitar o envio de mensagens, é necessário cadastrar o token vinculado à conexão.
            <ul>
              <li>Acesse o menu "Conexões".</li>
              <li>Clique no botão "Editar" da conexão desejada.</li>
              <li>Insira o token no campo apropriado.</li>
            </ul>
          </li>
          <li>
            <strong>Formatação do Número:</strong>
            <ul>
              <li>Código do país</li>
              <li>DDD</li>
              <li>Número</li>
            </ul>
          </li>
        </ul>
      </Paper>

      {/* 2. Mensagens de Texto */}
      <Paper elevation={0} className={classes.sectionPaper}>
        <Typography className={classes.sectionTitle}>
          1. Mensagens de Texto
        </Typography>
        <Grid container spacing={3}>
          {/* Coluna ESQUERDA: Informações necessárias */}
          <Grid item xs={12} sm={6}>
            {/* Título "Informações necessárias" */}
            <Typography className={classes.infoTitle2}>
              Informações necessárias:
            </Typography>
            <div className={classes.infoList}>
              {/* Endpoint */}
              <p><strong>Endpoint:</strong></p>
              <div className={classes.codeBlock}>
                {getEndpoint()}
              </div>

              {/* Método */}
              <p style={{ marginTop: 16 }}><strong>Método:</strong></p>
              <span className={classes.methodTag}>POST</span>

              {/* Headers */}
              <p style={{ marginTop: 16 }}><strong>Headers:</strong></p>
              <div className={classes.codeBlock}>
                Authorization (Bearer token)
                {"\n"}Content-Type (application/json)
              </div>

              {/* Body */}
              <p style={{ marginTop: 16 }}><strong>Body:</strong></p>
              <div className={classes.codeBlock}>
                {"{ \"number\": \"595985523065\", \"body\": \"Sua mensagem\" }"}
              </div>
            </div>
          </Grid>

          {/* Coluna DIREITA: Teste de Envio */}
          <Grid item xs={12} sm={6}>
            <Typography className={classes.testTitle}>Teste de Envio</Typography>
            {renderFormMessageText()}
          </Grid>
        </Grid>
      </Paper>

      {/* 3. Mensagens de Mídia */}
      <Paper elevation={0} className={classes.sectionPaper}>
        <Typography className={classes.sectionTitle}>
          2. Mensagens de Mídia
        </Typography>
        <Grid container spacing={3}>
          {/* Coluna ESQUERDA: Informações necessárias */}
          <Grid item xs={12} sm={6}>
            <Typography className={classes.infoTitle2}>
              Informações necessárias:
            </Typography>
            <div className={classes.infoList}>
              {/* Endpoint */}
              <p><strong>Endpoint:</strong></p>
              <div className={classes.codeBlock}>
                {getEndpoint()}
              </div>

              {/* Método */}
              <p style={{ marginTop: 16 }}><strong>Método:</strong></p>
              <span className={classes.methodTag}>POST</span>

              {/* Headers */}
              <p style={{ marginTop: 16 }}><strong>Headers:</strong></p>
              <div className={classes.codeBlock}>
                Authorization (Bearer token)
                {"\n"}Content-Type (multipart/form-data)
              </div>

              {/* FormData */}
              <p style={{ marginTop: 16 }}><strong>FormData:</strong></p>
              <div className={classes.codeBlock}>
                number: 5511998765432
                {"\n"}medias: arquivo
              </div>
            </div>
          </Grid>

          {/* Coluna DIREITA: Teste de Envio */}
          <Grid item xs={12} sm={6}>
            <Typography className={classes.testTitle}>Teste de Envio</Typography>
            {renderFormMessageMedia()}
          </Grid>
        </Grid>
      </Paper>

      {/* 4. Suporte */}
      <Typography
        className={classes.sectionTitle}
        style={{ textAlign: "center", marginTop: 20 }}
      >
        Suporte
      </Typography>
      <Typography className={classes.contentText} style={{ marginBottom: 0 }}>
        Para dúvidas ou suporte técnico, entre em contato com nossa equipe através do suporte.
      </Typography>
    </Paper>
  );
};

export default MessagesAPI;