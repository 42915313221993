// src/pages/Users/Users.js

import React, { useState, useEffect, useReducer, useContext } from "react";
import toast from "../../errors/toast";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Typography
} from "@material-ui/core";

import { green, red } from "@material-ui/core/colors";

import SearchIcon from "@material-ui/icons/Search";

import { FiEdit, FiTrash } from "react-icons/fi";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { SocketContext } from "../../context/Socket/SocketContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

// Importar o StatusPill
import StatusPill from "./StatusPill";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_USERS": {
      const users = action.payload;
      const newUsers = [];

      users.forEach((user) => {
        const userIndex = state.findIndex((u) => u.id === user.id);
        if (userIndex !== -1) {
          state[userIndex] = user;
        } else {
          newUsers.push(user);
        }
      });

      return [...state, ...newUsers];
    }
    case "UPDATE_USERS": {
      const user = action.payload;
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
        return [...state];
      } else {
        return [user, ...state];
      }
    }
    case "DELETE_USER": {
      const userId = action.payload;
      const userIndex = state.findIndex((u) => u.id === userId);
      if (userIndex !== -1) {
        state.splice(userIndex, 1);
      }
      return [...state];
    }
    case "RESET": {
      return [];
    }
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0,
    fontSize: "1.5rem", // Similar ao Quickemessages
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  headerSubtitle: {
    margin: 0,
    color: "#8E8E8E",
    fontSize: "0.95rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },

  // Cartão que envolve a tabela
  mainPaper: {
    border: "1px solid #E3E3E3", // cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles
  },

  // Tabela
  tableRoot: {
    borderCollapse: "separate",
    borderSpacing: 0
  },
  // Arredondar cabeçalho (cantos superiores)
  tableHeadRow: {
    backgroundColor: "#FAFAFA",
    "& th:first-child": {
      borderTopLeftRadius: 8
    },
    "& th:last-child": {
      borderTopRightRadius: 8
    }
  },
  tableHeadCell: {
    color: "#6B6B6B",
    fontWeight: "bold",
    borderBottom: "none",
    fontSize: "0.9rem"
  },
  tableCell: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "0.875rem",
    "&:first-child": {
      borderRadius: "0 0 0 8px"
    },
    "&:last-child": {
      borderRadius: "0 0 8px 0"
    }
  },

  // Campo de busca
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: 220,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d8d8d8"
    }
  },

  // Botão "Adicionar"
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,

    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },

  // Ações de edição/remoção
  actionButton: {
    margin: theme.spacing(0, 0.5)
  },
  editIcon: {
    color: "#000"
  },
  deleteIcon: {
    color: "#E03D3D"
  },

  // Botão de Ativar/Desativar
  toggleButton: {
    margin: theme.spacing(0, 0.5),
  },
  activateButton: {
    color: green[600],
  },
  deactivateButton: {
    color: red[600],
  },
}));

const Users = () => {
  const classes = useStyles();
  const socketManager = useContext(SocketContext);

  // Estados principais (busca, lista, paginação etc.)
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deletingUser, setDeletingUser] = useState(null);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [users, dispatch] = useReducer(reducer, []);

  // Ao alterar busca, reseta a lista
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  // Busca usuários do backend com debounce
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber }
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  // Socket
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-user`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  // Modal de criação/edição
  const handleOpenUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setSelectedUser(null);
    setUserModalOpen(false);
  };

  // Campo de busca
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // Edição
  const handleEditUser = (user) => {
    setSelectedUser(user);
    setUserModalOpen(true);
  };

  // Exclusão
  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      toast.success(i18n.t("users.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  // Alternar status do usuário (Ativo/Inativo)
  const handleToggleStatus = async (user) => {
    try {
      const updatedStatus = !user.online;
      await api.put(`/users/${user.id}/status`, { online: updatedStatus });
      dispatch({
        type: "UPDATE_USERS",
        payload: { ...user, online: updatedStatus }
      });
      toast.success(
        updatedStatus
          ? i18n.t("users.toasts.online")
          : i18n.t("users.toasts.offline")
      );
    } catch (err) {
      toastError(err);
    }
  };

  // Paginação ("loadMore")
  const loadMore = () => {
    setPageNumber((prev) => prev + 1);
  };

  // Rolagem infinita no Paper
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      {/* Modal de confirmação de exclusão */}
      <ConfirmationModal
        title={
          deletingUser &&
          `${i18n.t("users.confirmationModal.deleteTitle")} ${deletingUser.name}?`
        }
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      >
        {i18n.t("users.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      {/* Modal de criação/edição */}
      <UserModal
        open={userModalOpen}
        onClose={handleCloseUserModal}
        aria-labelledby="form-dialog-title"
        userId={selectedUser && selectedUser.id}
      />

      {/* Cabeçalho */}
      <MainHeader>
        <div>
          <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("users.title")}
          </Typography>
          <Typography className={classes.headerSubtitle}>
            {i18n.t("users.subtitleDescription")}
          </Typography>
        </div>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder") || "Pesquisar..."}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            variant="outlined"
            size="small"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={handleOpenUserModal}
          >
            {i18n.t("users.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* Tabela */}
      <Paper className={classes.mainPaper} onScroll={handleScroll}>
        <Table size="small" className={classes.tableRoot}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.id")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.status")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.name")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.email")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.profile")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("users.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow hover key={user.id}>
                <TableCell align="center" className={classes.tableCell}>
                  {user.id}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {/* Utilizando o StatusPill */}
                  <StatusPill status={user.online ? 'Online' : 'Offline'} />
                                  </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.name}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.email}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {user.profile}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  <IconButton
                    size="small"
                    className={classes.actionButton}
                    onClick={() => handleEditUser(user)}
                  >
                    <FiEdit className={classes.editIcon} />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={classes.actionButton}
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeletingUser(user);
                    }}
                  >
                    <FiTrash className={classes.deleteIcon} />
                  </IconButton>
                  {/* Botão para alternar status */}
                  <IconButton
                    size="small"
                    className={classes.toggleButton}
                    onClick={() => handleToggleStatus(user)}
                  >
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={6} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Users;