// src/components/UserModal/index.js

import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";

import { Visibility, VisibilityOff } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  dialogPaper: {
    borderRadius: "12px", // Bordas arredondadas da modal
  },
  headerTitle: {
    fontWeight: "bold",
    color: "#6F2CC0",
    fontSize: "1.5rem",
  },
  headerSubtitle: {
    color: "#666",
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 12,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },

  restartButton: {
    backgroundColor: "#FFF",
    color: "#6F2CC0",
    textTransform: "none",
    borderRadius: 12,
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
    border: `1px solid #6F2CC0`,
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: "#EFE9F8",
    },
  },
  tableHeadCell: {
    fontWeight: "bold",
    color: "#6B6B6B",
    textAlign: "center",
    padding: theme.spacing(1),
  },
  tableCell: {
    textAlign: "center",
    color: "#333",
    fontSize: "0.9rem",
    borderBottom: "1px solid #F0F0F0",
    padding: theme.spacing(1),
  },
  statusBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "4px 8px",
    fontSize: "0.875rem",
    fontWeight: "500",
    textTransform: "capitalize",
    gap: "6px",
  },
  statusConnected: {
    backgroundColor: "#E6FFE6",
    color: "#228B22",
  },
  outlinedInput: {
    borderRadius: "13px !important", // Aplica bordas arredondadas com 13px
  },
  statusDisconnected: {
    backgroundColor: "#FFE6E6",
    color: "#D32F2F",
  },
  statusDot: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  statusDotConnected: {
    backgroundColor: "#228B22",
  },
  statusDotDisconnected: {
    backgroundColor: "#D32F2F",
  },
  disconnectButton: {
    backgroundColor: "#E63946",
    color: "#FFFFFF",
    fontSize: "0.875rem",
    fontWeight: "500",
    textTransform: "none",
    borderRadius: "8px",
    padding: "6px 16px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#C62828",
    },
  },
  actionButton: {
    margin: theme.spacing(0, 0.5),
    padding: "8px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  editIcon: {
    color: "#000",
  },
  deleteIcon: {
    color: "#E03D3D",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btnWrapper: {
    position: "relative",
  },
  btnLeft: {
    display: "flex",
    marginRight: "auto",
    marginLeft: 12,
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // Bordas arredondadas para os campos de preenchimento
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: "white",
      padding: "0 4px", // Ajuste do padding para alinhar o label
      borderRadius: "8px",
      transform: "translate(14px, -6px) scale(0.75)", // Ajuste da posição do label
    },
  },
  // Estilizando os botões com bordas arredondadas
  button: {
    borderRadius: "12px", // Bordas arredondadas nos botões
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
  },
  // Estilizando o botão de "Excluir" com cor vermelha
  deleteButton: {
    backgroundColor: "#F44336", // Cor vermelha para exclusão
    color: "white",
  },
  formControl: {
    borderRadius: "8px", // Arredondando as bordas do FormControl
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Bordas arredondadas para o Select
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: "white",
      padding: "0 4px", // Ajuste do padding para alinhar o label
      borderRadius: "8px",
      transform: "translate(14px, -6px) scale(0.75)", // Ajuste da posição do label
    },
  },
}));

/* -----------------------------------------------------
   Componente CustomToolTip (Opcional)
----------------------------------------------------- */
const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

/* -----------------------------------------------------
   Componente UserModal
----------------------------------------------------- */
const UserModal = ({ open, onClose, userId }) => {
  const classes = useStyles();

  const initialState = {
    name: "",
    email: "",
    password: "",
    profile: "user",
    allTicket: "desabled",
    startWork: "",
    endWork: "",
    queueIds: [], // Adicionado para múltiplas filas
  };

  const { user: loggedInUser } = useContext(AuthContext);
  const { loading, whatsApps } = useWhatsApps();

  const [user, setUser] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [whatsappId, setWhatsappId] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const startWorkRef = useRef();
  const endWorkRef = useRef();

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) return;
      try {
        const { data } = await api.get(`/users/${userId}`);
        setUser((prevState) => {
          return { ...prevState, ...data };
        });
        const userQueueIds = data.queues?.map((queue) => queue.id) || [];
        setSelectedQueueIds(userQueueIds);
        setWhatsappId(data.whatsappId ? data.whatsappId : "");
      } catch (err) {
        toastError(err);
      }
    };

    fetchUser();
  }, [userId, open]);

  const handleClose = () => {
    onClose();
    setUser(initialState);
    setSelectedQueueIds([]);
    setWhatsappId("");
  };

  const handleSaveUser = async (values) => {
    const userData = {
      ...values,
      whatsappId,
      queueIds: values.queueIds, // Usar os valores do Formik
      allTicket: values.allTicket,
    };
    try {
      if (userId) {
        await api.put(`/users/${userId}`, userData);
      } else {
        await api.post("/users", userData);
      }
      toast.success(i18n.t("userModal.success"));
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  // Definição do UserSchema
  const UserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Muito curto!")
      .max(50, "Muito longo!")
      .required("Obrigatório"),
    password: Yup.string().min(5, "Muito curto!").max(50, "Muito longo!"),
    email: Yup.string().email("Email inválido").required("Obrigatório"),
    queueIds: Yup.array()
      .min(1, "Selecione pelo menos uma fila")
      .required("Obrigatório"),
  });

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm" // Mantendo "sm" para uma modal mais compacta
        scroll="paper"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title">
          {userId
            ? i18n.t("userModal.title.edit")
            : i18n.t("userModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={UserSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveUser(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => (
            <Form>
              <Paper square className={classes.mainPaper} elevation={1}>
                <DialogContent dividers>
                  <Grid container spacing={2}>
                    {/* Nome e Senha */}
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        name="password"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        label={i18n.t("userModal.form.password")}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        className={classes.textField} // Aplicando a classe para bordas arredondadas

                      />
                    </Grid>

                    {/* Email e Perfil */}
                    <Grid item xs={12} md={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("userModal.form.email")}
                        name="email"
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        className={classes.textField}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl} // Usando a classe modificada
                        margin="dense"
                        fullWidth
                      >
                        <Can
                          role={loggedInUser.profile}
                          perform="user-modal:editProfile"
                          yes={() => (
                            <>
                              <InputLabel id="profile-selection-input-label">
                                {i18n.t("userModal.form.profile")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.profile")}
                                name="profile"
                                labelId="profile-selection-input-label"
                                id="profile-selection"
                                required
                              >
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="user">User</MenuItem>
                              </Field>
                            </>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    {/* Filas */}
                    <Grid item xs={12} md={6}>
                      <FormControl
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                      >
                        <QueueSelect
                          selectedQueueIds={values.queueIds}
                          onChange={(selectedIds) => {
                            setFieldValue("queueIds", selectedIds);
                            setSelectedQueueIds(selectedIds);
                          }}
                          labelId="queue-label"
                          label={i18n.t("whatsappModal.form.sendIdQueue")}
                        />
                      </FormControl>
                    </Grid>

                    {/* Seleção de WhatsApp */}
                    <Grid item xs={12} md={6} style={{ marginTop: '7px' }}>
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() => (
                          <FormControl
                            variant="outlined"
                            margin="dense"
                            className={classes.formControl}
                            fullWidth
                          >
                            <InputLabel id="whatsapp-selection-input-label">
                              {i18n.t("userModal.form.whatsapp")}
                            </InputLabel>

                            <Field
                              as={Select}
                              label={i18n.t("userModal.form.whatsapp")}
                              name="whatsappId"
                              labelId="whatsapp-selection-input-label"
                              id="whatsapp-selection"
                              value={whatsappId}
                              onChange={(e) => setWhatsappId(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>&nbsp;</em>
                              </MenuItem>
                              {whatsApps.map((whatsapp) => (
                                <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                  {whatsapp.name}
                                </MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        )}
                      />
                    </Grid>

                    {/* Seleção de Ticket */}
                    <Grid item xs={12} md={6}>
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() =>
                          !loading && (
                            <FormControl
                              variant="outlined"
                              margin="dense"
                              className={classes.formControl}
                              fullWidth
                            >
                              <InputLabel id="allTicket-selection-input-label">
                                {i18n.t("userModal.form.allTicket")}
                              </InputLabel>

                              <Field
                                as={Select}
                                label={i18n.t("userModal.form.allTicket")}
                                name="allTicket"
                                labelId="allTicket-selection-input-label"
                                id="allTicket-selection"
                                required
                              >
                                <MenuItem value="enabled">
                                  {i18n.t("userModal.form.allTicketEnabled")}
                                </MenuItem>
                                <MenuItem value="desabled">
                                  {i18n.t("userModal.form.allTicketDesabled")}
                                </MenuItem>
                              </Field>
                            </FormControl>
                          )
                        }
                      />
                    </Grid>

                    {/* Horário de Trabalho */}
                    <Grid item xs={12} md={6}>
                      <Can
                        role={loggedInUser.profile}
                        perform="user-modal:editProfile"
                        yes={() =>
                          !loading && (
                            <Grid container spacing={2} alignItems="center">
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  label={i18n.t("userModal.form.startWork")}
                                  type="time"
                                  ampm={false}
                                  defaultValue="00:00"
                                  inputRef={startWorkRef}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 600, // 5 min
                                  }}
                                  name="startWork"
                                  error={
                                    touched.startWork && Boolean(errors.startWork)
                                  }
                                  helperText={
                                    touched.startWork && errors.startWork
                                  }
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  className={classes.textField}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  label={i18n.t("userModal.form.endWork")}
                                  type="time"
                                  ampm={false}
                                  defaultValue="23:59"
                                  inputRef={endWorkRef}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    step: 600, // 5 min
                                  }}
                                  name="endWork"
                                  error={
                                    touched.endWork && Boolean(errors.endWork)
                                  }
                                  helperText={
                                    touched.endWork && errors.endWork
                                  }
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                  className={classes.textField}
                                />
                              </Grid>
                            </Grid>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </Paper>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="default"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.button}
                  style={{
                    marginRight: "8px",
                  }}
                >
                  {i18n.t("userModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.button}
                  style={{
                    backgroundColor: "#6f2cc0", // Cor para o botão de salvar
                  }}
                >
                  {userId
                    ? `${i18n.t("tagModal.buttons.okEdit")}`
                    : `${i18n.t("tagModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default UserModal;