import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Grid,
  IconButton,
  StepContent,
  TextField
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AttachFile, DeleteOutline } from "@material-ui/icons";
import { head } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
  },
  stepper: {
    backgroundColor: "transparent", // Remove cor de fundo
    marginBottom: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  stepLabel: {
    cursor: "pointer",
    fontSize: 16,
    "& .MuiStepLabel-label": {
      fontSize: 16,
      fontFamily: "Montserrat, sans-serif",
      // Removido o fontWeight: 600 daqui
    },
  },
  stepContent: {
    marginLeft: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.grey[200]}`,
    paddingLeft: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(1),
    borderRadius: 12,
    textTransform: "none",
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 12,
    textTransform: "none",
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& .MuiOutlinedInput-root": {
      borderRadius: 6,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 12px",
      fontSize: 14,
    },
  },
  iconButton: {
    borderRadius: 12,
    padding: 6,
  },
  attachContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  attachButton: {
    borderRadius: 12,
    textTransform: "none",
    fontSize: 14,
    border: "1px solid #ccc",
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },
  titleText: {
    // Removido o fontWeight: 600
    fontSize: 16,
    fontFamily: "Montserrat, sans-serif",
  },
}));

export function QueueOptionStepper({ queueId, options, updateOptions }) {
  const classes = useStyles();
  const [activeOption, setActiveOption] = useState(-1);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleOption = (index) => async () => {
    setActiveOption(index);
    const option = options[index];

    if (option !== undefined && option.id !== undefined) {
      try {
        const { data } = await api.request({
          url: "/queue-options",
          method: "GET",
          params: { queueId, parentId: option.id },
        });
        const optionList = data.map((opt) => {
          return {
            ...opt,
            children: [],
            edition: false,
          };
        });
        option.children = optionList;
        updateOptions();
      } catch (e) {
        toastError(e);
      }
    }
  };

  const handleSave = async (option) => {
    try {
      if (option.id) {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "PUT",
          data: option,
        });
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/queue-options/${option.id}/media-upload`, formData);
        }
      } else {
        const { data } = await api.request({
          url: `/queue-options`,
          method: "POST",
          data: option,
        });
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/queue-options/${option.id}/media-upload`, formData);
        }
        option.id = data.id;
      }
      option.edition = false;
      updateOptions();
    } catch (e) {
      toastError(e);
    }
  };

  const handleEdition = (index) => {
    options[index].edition = !options[index].edition;
    updateOptions();
  };

  const handleDeleteOption = async (index) => {
    const option = options[index];
    if (option && option.id !== undefined) {
      try {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "DELETE",
        });
      } catch (e) {
        toastError(e);
      }
    }
    options.splice(index, 1);
    // Reordena as opções existentes
    options.forEach(async (opt, order) => {
      opt.option = order + 1;
      await handleSave(opt);
    });
    updateOptions();
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleOptionChangeTitle = (event, index) => {
    options[index].title = event.target.value;
    updateOptions();
  };

  const handleOptionChangeMessage = (event, index) => {
    options[index].message = event.target.value;
    updateOptions();
  };

  const renderTitle = (index) => {
    const option = options[index];
    if (option.edition) {
      return (
        <>
          <TextField
            value={option.title}
            onChange={(event) => handleOptionChangeTitle(event, index)}
            size="small"
            variant="outlined"
            className={classes.input}
            placeholder="Título da opção"
          />
          {/* Campo de upload (invisível) */}
          <div style={{ display: "none" }}>
            <input
              type="file"
              ref={attachmentFile}
              onChange={handleAttachmentFile}
            />
          </div>

          {option.edition && (
            <>
              <IconButton
                color="primary"
                size="small"
                className={classes.iconButton}
                onClick={() => handleSave(option)}
              >
                <SaveIcon />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                className={classes.iconButton}
                onClick={() => handleDeleteOption(index)}
              >
                <DeleteOutlineIcon />
              </IconButton>

              {!attachment && !option.mediaPath && (
                <IconButton
                  color="primary"
                  size="small"
                  className={classes.iconButton}
                  onClick={() => attachmentFile.current.click()}
                >
                  <AttachFile />
                </IconButton>
              )}

              {(option.mediaPath || attachment) && (
                <Grid xs={12} item className={classes.attachContainer}>
                  <Button
                    startIcon={<AttachFile />}
                    className={classes.attachButton}
                  >
                    {attachment != null ? attachment.name : option.mediaName}
                  </Button>
                  <IconButton
                    onClick={() => setConfirmationOpen(true)}
                    color="secondary"
                    size="small"
                    className={classes.iconButton}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Grid>
              )}
            </>
          )}
        </>
      );
    }
    return (
      <>
        <Typography className={classes.titleText}>
          {option.title !== "" ? option.title : "Título não definido"}
          <IconButton
            size="small"
            className={classes.iconButton}
            onClick={() => handleEdition(index)}
          >
            <EditIcon />
          </IconButton>
        </Typography>
      </>
    );
  };

  const renderMessage = (index) => {
    const option = options[index];
    if (option.edition) {
      return (
        <TextField
          style={{ width: "100%" }}
          multiline
          variant="outlined"
          value={option.message}
          onChange={(event) => handleOptionChangeMessage(event, index)}
          size="small"
          className={classes.input}
          placeholder="Digite o texto da opção"
        />
      );
    }
    return (
      <Typography onClick={() => handleEdition(index)}>
        {option.message}
      </Typography>
    );
  };

  const handleAddOption = (index) => {
    const optionNumber = options[index].children.length + 1;
    options[index].children.push({
      title: "",
      message: "",
      edition: false,
      option: optionNumber,
      queueId,
      parentId: options[index].id,
      children: [],
    });
    updateOptions();
  };

  const renderStep = (option, index) => {
    return (
      <Step key={index}>
        <StepLabel
          onClick={handleOption(index)}
          className={classes.stepLabel}
        >
          {renderTitle(index)}
        </StepLabel>

        <StepContent className={classes.stepContent}>
          {renderMessage(index)}

          {option.id !== undefined && (
            <Button
              color="primary"
              size="small"
              onClick={() => handleAddOption(index)}
              startIcon={<AddIcon />}
              variant="outlined"
              className={classes.addButton}
            >
              Adicionar
            </Button>
          )}

          {/* Recursividade: exibe sub-opções (children) */}
          <QueueOptionStepper
            queueId={queueId}
            options={option.children}
            updateOptions={updateOptions}
          />
        </StepContent>
      </Step>
    );
  };

  const renderStepper = () => (
    <Stepper
      nonLinear
      activeStep={activeOption}
      orientation="vertical"
      className={classes.stepper}
    >
      {options.map((option, index) => renderStep(option, index))}
    </Stepper>
  );

  return renderStepper();
}

export function QueueOptions({ queueId }) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (queueId) {
      const fetchOptions = async () => {
        try {
          const { data } = await api.request({
            url: "/queue-options",
            method: "GET",
            params: { queueId, parentId: -1 },
          });
          const optionList = data.map((opt) => {
            return {
              ...opt,
              children: [],
              edition: false,
            };
          });
          setOptions(optionList);
        } catch (e) {
          toastError(e);
        }
      };
      fetchOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueId]);

  const updateOptions = () => {
    setOptions([...options]);
  };

  const addOption = () => {
    const newOption = {
      title: "",
      message: "",
      edition: false,
      option: options.length + 1,
      queueId,
      parentId: null,
      children: [],
    };
    setOptions([...options, newOption]);
  };

  const renderStepper = () => {
    if (options.length > 0) {
      return (
        <QueueOptionStepper
          queueId={queueId}
          updateOptions={updateOptions}
          options={options}
        />
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      {/* Margem superior adicionada para espaçar do campo acima */}
      <Typography
        variant="body1"
        style={{ fontSize: 16, marginTop: 16 }}
      >
        {/* Removido fontWeight: 600 para não ficar em negrito */}
        Opções
        <Button
          color="primary"
          size="small"
          onClick={addOption}
          startIcon={<AddIcon />}
          variant="outlined"
          className={classes.button}
          style={{ marginLeft: 10 }}
        >
          Adicionar
        </Button>
      </Typography>

      {renderStepper()}
    </div>
  );
}