import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Ícones
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import CallIcon from "@material-ui/icons/Call";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import StoreIcon from "@material-ui/icons/Store";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import TimerIcon from "@material-ui/icons/Timer";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Button } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import GroupIcon from "@material-ui/icons/Group";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ForumIcon from "@material-ui/icons/Forum";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from "@material-ui/icons/Send";
import MessageIcon from "@material-ui/icons/Message";

import {
  Paper,
  Container,
  Grid,
  Typography,
  Box
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";
import toast from "../../errors/toast"; // Importando o toast que agora usa o toastSuccess personalizado

import Chart from "./Chart";
import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import TableAttendantsStatus from "../../components/Dashboard/TableAttendantsStatus";
import { isArray, isEmpty } from "lodash";

import { AuthContext } from "../../context/Auth/AuthContext";

import useDashboard from "../../hooks/useDashboard";
import useTickets from "../../hooks/useTickets";
import useUsers from "../../hooks/useUsers";
import useContacts from "../../hooks/useContacts";
import useMessages from "../../hooks/useMessages";
import { ChatsUser } from "./ChartsUser";
import Filters from "./Filters";
import moment from "moment";
import { ChartsDate } from "./ChartsDate";

const useStyles = makeStyles((theme) => ({
  // -------------------- FILTROS --------------------
  alignRight: {
    textAlign: "right",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  fullWidth: {
    width: "100%",
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  customFixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 120,
  },
  customFixedHeightPaperLg: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: "100%",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  container2: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
  },
  // -------------------- CARDS --------------------
  card: {
    minHeight: "125px",
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    padding: theme.spacing(2),
    borderRadius: 8,
    transition: "0.3s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
    },
  },
  LPaper: {
    boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
    background: "white",
    padding: "20px",
    width: "100%",
    borderRadius: "13px",
    display: "flex", [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  cardIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Remova ou comente as linhas abaixo para não criar o círculo
    // width: 60,
    // height: 60,
    // borderRadius: "50%",
    // backgroundColor: "rgba(255, 255, 255, 0.2)",
  },

  cardTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "black",
    marginBottom: 4,
  },
  cardParagrafo: {
    fontSize: 12,
    color: "green",
    marginBottom: 4,
  },
  cardValue: {
    fontSize: 28,
    fontWeight: 700,
    color: "#000000",
  },

  // Exemplos de cores que podem ser ajustadas para cada card conforme a imagem
  // (Grey, Purple, White, Black). Os nomes "card1", "card2", etc. foram mantidos
  // para não quebrar a lógica já existente no código.
  card1: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
  },
  card2: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",

  },
  card3: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    "& $cardIconContainer svg": {
      color: "black",
    },
  },
  card4: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",

    "& $cardValue": {
      color: "#000000",
    },
    // Remova as referências que afetam o cardIconContainer
    // "& $cardIconContainer": {
    //   backgroundColor: "#EFEAFB",
    //   "& svg": {
    //     color: "#6F2CC0",
    //   },
    // },
  },
  card5: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    // Remova as referências que afetam o cardIconContainer
    // "& $cardIconContainer": {
    //   backgroundColor: "#EFEAFB",
    //   "& svg": {
    //     color: "#6F2CC0",
    //   },
    // },
  },
  card6: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    // Remova as referências que afetam o cardIconContainer
    // "& $cardIconContainer": {
    //   backgroundColor: "#EFEAFB",
    //   "& svg": {
    //     color: "#6F2CC0",
    //   },
    // },
  },
  card7: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    // Remova as referências que afetam o cardIconContainer
    // "& $cardIconContainer": {
    //   backgroundColor: "#EFEAFB",
    //   "& svg": {
    //     color: "#6F2CC0",
    //   },
    // },
  },
  card8: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",

    "& $cardValue": {
      color: "#000000",
    },
    // Remova as referências que afetam o cardIconContainer
    // "& $cardIconContainer": {
    //   backgroundColor: "#EFEAFB",
    //   "& svg": {
    //     color: "#6F2CC0",
    //   },
    // },
  },
  card9: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    "& $cardIconContainer svg": {
      color: "black",
    },
  },
  card0: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    // Forçando que qualquer svg dentro do cardIconContainer fique branco
    "& $cardIconContainer svg": {
      color: "black",
    },
  },
  card00: {
    boxShadow: "-3px 0px 0px 0px #6F2CC0",
    border: "2px solid #6F2CC0",
    "& $cardTitle": {
      color: "black",
    },
    "& $cardValue": {
      color: "black",
    },
    "& $cardIconContainer svg": {
      color: "black",
    },
  },
  ContainerChart:{
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },
  },
  cardTL:{
    color: "#6F2CC0",
    fontSize: 21,
    fontWeight: 500,
    marginBottom: 4,
  },
  espace:{
    paddingRight: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px"
    },
  }
}));

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const [counters, setCounters] = useState({});
  const [attendants, setAttendants] = useState([]);
  const [period, setPeriod] = useState(0);
  const [filterType, setFilterType] = useState(1);
  const [dateFrom, setDateFrom] = useState(
    moment("1", "D").format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { find } = useDashboard();

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let now = `${year}-${month < 10 ? `0${month}` : `${month}`}-${date < 10 ? `0${date}` : `${date}`
    }`;

  const [showFilter, setShowFilter] = useState(false);
  const [queueTicket, setQueueTicket] = useState(false);

  const { user } = useContext(AuthContext);
  var userQueueIds = [];

  if (user.queues && user.queues.length > 0) {
    userQueueIds = user.queues.map((q) => q.id);
  }

  useEffect(() => {
    if (user.confirmedphone !== "true") {
      history.push("/phone");
    }

    async function firstLoad() {
      await fetchData();
    }
    setTimeout(() => {
      firstLoad();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleChangePeriod(value) {
    setPeriod(value);
  }

  async function handleChangeFilterType(value) {
    setFilterType(value);
    if (value === 1) {
      setPeriod(0);
    } else {
      setDateFrom("");
      setDateTo("");
    }
  }

  async function fetchData() {
    setLoading(true);

    let params = {};

    if (period > 0) {
      params = {
        days: period,
      };
    }

    if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
      params = {
        ...params,
        date_from: moment(dateFrom).format("YYYY-MM-DD"),
      };
    }

    if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
      params = {
        ...params,
        date_to: moment(dateTo).format("YYYY-MM-DD"),
      };
    }

    if (Object.keys(params).length === 0) {
      toast.error("Parametrize o filtro");
      setLoading(false);
      return;
    }

    const data = await find(params);

    setCounters(data.counters);
    if (isArray(data.attendants)) {
      setAttendants(data.attendants);
    } else {
      setAttendants([]);
    }

    setLoading(false);
  }

  function formatTime(minutes) {
    return moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m]");
  }

  const GetUsers = () => {
    let userOnline = 0;
    attendants.forEach((u) => {
      if (u.online === true) {
        userOnline++;
      }
    });
    return userOnline === 0 ? 0 : userOnline;
  };

  const GetContacts = (all) => {
    let props = {};
    if (all) {
      props = {};
    }
    const { count } = useContacts(props);
    return count;
  };

  function renderFilters() {
    if (filterType === 1) {
      return (
        <>
          <Grid item xs={12} sm={6} md={6} className={`${classes.selectContainer} ${classes.espace}`}>
            <TextField
              label="Data Inicial"
              type="date"
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={`${classes.selectContainer} ${classes.espace}`}>
            <TextField
              label="Data Final"
              type="date"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className={classes.fullWidth}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item xs={12} sm={6} md={6}>
          <FormControl className={`${classes.selectContainer} ${classes.espace}`}>
            <InputLabel id="period-selector-label">Período</InputLabel>
            <Select
              labelId="period-selector-label"
              id="period-selector"
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
            >
              <MenuItem value={0}>Nenhum selecionado</MenuItem>
              <MenuItem value={3}>Últimos 3 dias</MenuItem>
              <MenuItem value={7}>Últimos 7 dias</MenuItem>
              <MenuItem value={15}>Últimos 15 dias</MenuItem>
              <MenuItem value={30}>Últimos 30 dias</MenuItem>
              <MenuItem value={60}>Últimos 60 dias</MenuItem>
              <MenuItem value={90}>Últimos 90 dias</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      );
    }
  }




  return (

    <div>

      <Container maxWidth="lg" className={classes.container}>
        <Container item xs={12} maxWidth="lg" className={`${classes.container2} ${classes.LPaper}`} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl className={`${classes.selectContainer} ${classes.espace}`} >
              <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
              <Select
                labelId="period-selector-label"
                value={filterType}
                onChange={(e) => handleChangeFilterType(e.target.value)}
              >
                <MenuItem value={1}>Filtro por Data</MenuItem>
                <MenuItem value={2}>Filtro por Período</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {renderFilters()}

          {/* BOTÃO FILTRAR */}
          <Grid item xs={12} style={{ justifyContent: "end", display: "flex" }} >
            <ButtonWithSpinner
              loading={loading}
              onClick={() => fetchData()}
              variant="contained"
              color="primary"
              style={{
                maxWidth: "40px",
                color: "#fff",
                fontWeight: "bold",
                borderRadius: "8px",
                textTransform: "none",
                backgroundColor: "#6F2CC0",
              }}
            >
              Filtrar
            </ButtonWithSpinner>
          </Grid>
        </Container>
        <Grid container spacing={3} justifyContent="flex-end" >
          {/* Atd. Acontecendo (cinza claro) */}
          <Grid   item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card2} `} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>
                  Acontecendo
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportHappening}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                  +12% from last period
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <HourglassEmptyIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* Atd. Pendentes (roxo) */}
          <Grid  item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card1}`} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>
                  Pendentes
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportPending}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                  +12% from last period
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <CallIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* Novos Contatos (branco) */}
          <Grid  item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card4}`} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>
                  Novos Contatos
                </Typography>
                <Typography className={classes.cardValue}>
                  {counters.leads}
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <PersonAddIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* T.M. de Atendimento (branco) */}
          <Grid  item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card8}`} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>
                  Atendimento
                </Typography>
                <Typography className={classes.cardValue}>
                  {formatTime(counters.avgSupportTime)}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                  +12% from last period
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <AccessAlarmIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* T.M. de Espera (vermelho) */}
          <Grid  item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card9}`} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>
                  Espera
                </Typography>
                <Typography className={classes.cardValue}>
                  {formatTime(counters.avgWaitTime)}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                  +12% from last period
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <TimerIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* Finalizados (verde) */}
          <Grid  item xs={12} sm={6} md={4}>
            <Paper className={`${classes.card} ${classes.card3}`} elevation={8}>
              <Box>
                <Typography className={classes.cardTitle}>Finalizados</Typography>
                <Typography className={classes.cardValue}>
                  {counters.supportFinished}
                </Typography>
                <Typography className={classes.cardParagrafo}>
                  +12% from last period
                </Typography>
              </Box>
              <Box className={classes.cardIconContainer}>
                <DoneAllIcon style={{ fontSize: 15 }} />
              </Box>
            </Paper>
          </Grid>

          {/* Conexões Ativas (preto)
          {user.super && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={`${classes.card} ${classes.card0}`}
                elevation={8}
              >
                <Box>
                  <Typography className={classes.cardTitle}>
                    Conexões Ativas
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {counters.totalWhatsappSessions}
                  </Typography>
                  <Typography className={classes.cardParagrafo}>
                +12% from last period
                </Typography>
                </Box>
                <Box className={classes.cardIconContainer}>
                  <MobileFriendlyIcon style={{ fontSize: 15 }} />
                </Box>
              </Paper>
            </Grid>
          )} */}

          {/* Empresas (preto)
          {user.super && (
            <Grid item xs={12} sm={6} md={4}>
              <Paper
                className={`${classes.card} ${classes.card00}`}
                elevation={8}
              >
                <Box>
                  <Typography className={classes.cardTitle}>Empresas</Typography>
                  <Typography className={classes.cardValue}>
                    {counters.totalCompanies}
                  </Typography>
                  <Typography className={classes.cardParagrafo}>
                +12% from last period
                </Typography>
                </Box>
                <Box className={classes.cardIconContainer}>
                  <StoreIcon style={{ fontSize: 15 }} />
                </Box>
              </Paper>
            </Grid>
          )} */}



          {/* USUÁRIOS ONLINE (TABELA) */}
          <Grid item xs={12} md={12}>
            
            <Paper className={`${classes.LPaper}`} style={{flexDirection: "column",}} >
            <Typography className={classes.cardTL}>Atendentes</Typography>

              {attendants.length ? (
                <TableAttendantsStatus attendants={attendants} loading={loading} />
              ) : null}
            </Paper>
          </Grid>

        
          {/* GRÁFICOS */}
          <Grid   className={`${classes.ContainerChart}`} spacing={3}   >
            {/* TOTAL DE ATENDIMENTOS */}
            <Paper className={`${classes.LPaper}`} style={{
                margin: "5px",
                
              }}>
              <Grid item xs={12} md={12}>
                <ChartsDate />
              </Grid>
            </Paper>
            {/* TOTAL DE ATENDIMENTOS POR USUÁRIO */}

            <Paper className={`${classes.LPaper}`} style={{
                margin: "5px",
                
              }} >
              <Grid item xs={12} md={12}>
                <ChatsUser />
              </Grid>
            </Paper>


          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;