import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import toast from "../../errors/toast";


// Ícones Feather
import { FiEdit, FiTrash } from "react-icons/fi";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import { Typography } from '@material-ui/core';

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { SocketContext } from "../../context/Socket/SocketContext";
import { AuthContext } from "../../context/Auth/AuthContext";

// Reducer function
const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const tags = action.payload;
    const newTags = [];

    tags.forEach((tag) => {
      const tagIndex = state.findIndex((s) => s.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newTags.push(tag);
      }
    });

    return [...state, ...newTags];
  }

  if (action.type === "UPDATE_TAGS") {
    const tag = action.payload;
    const tagIndex = state.findIndex((s) => s.id === tag.id);

    if (tagIndex !== -1) {
      state[tagIndex] = tag;
      return [...state];
    } else {
      return [tag, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const tagId = action.payload;

    const tagIndex = state.findIndex((s) => s.id === tagId);
    if (tagIndex !== -1) {
      state.splice(tagIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  // Cartão que envolve a tabela
  mainPaper: {
    border: "1px solid #E3E3E3",    // cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles
  },
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0
  },
  headerSubtitle: {
    margin: 0,
    color: "#8E8E8E",
    fontSize: "0.95rem"
  },
  // Botão "Adicionar"
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,

    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },
  // Campo de busca
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: 220,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d8d8d8"
    }
  },
  // Tabela
  tableRoot: {
    borderCollapse: "separate",
    borderSpacing: 0
  },
  // Arredondar cabeçalho (cantos superiores)
  tableHeadRow: {
    backgroundColor: "#FAFAFA",
    "& th:first-child": {
      borderTopLeftRadius: 8
    },
    "& th:last-child": {
      borderTopRightRadius: 8
    }
  },
  tableHeadCell: {
    color: "#6B6B6B",
    fontWeight: "bold",
    borderBottom: "none",
    fontSize: "0.9rem"
  },
  tableCell: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "0.875rem",
    "&:first-child": {
      borderRadius: "0 0 0 8px"
    },
    "&:last-child": {
      borderRadius: "0 0 8px 0"
    }
  },
  chip: {
    borderRadius: "9px",
    padding: "4px 8px",
    textTransform: "capitalize",
  },
  // Ações de edição/remoção
  actionButton: {
    margin: theme.spacing(0, 0.5)
  },
  editIcon: {
    color: "#000"
  },
  deleteIcon: {
    color: "#E03D3D"
  },
}));

const Tags = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, dispatch] = useReducer(reducer, []);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  const fetchTags = useCallback(async () => {
    try {
      const { data } = await api.get("/tags/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_TAGS", payload: data.tags });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchTags();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchTags]);

  useEffect(() => {
    const socket = socketManager.getSocket(user.companyId);

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.tags });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.tagId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socketManager, user]);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchTags();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={deletingTag && `${i18n.t("tags.confirmationModal.deleteTitle")}`}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteTag(deletingTag.id)}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        reload={fetchTags}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
      />


      {/* Cabeçalho */}
      <MainHeader>
        <div>
          <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("tags.title")}
          </Typography>
        </div>
        <MainHeaderButtonsWrapper>

          <TextField
            placeholder={i18n.t("quickMessages.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            variant="outlined"
            size="small"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenTagModal}
            className={classes.addButton}
          >
            Adicionar Tag
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={loadMore}
      >
        <Table size="small">
          <Table size="small" className={classes.tableRoot}>
            <TableHead>
              <TableRow className={classes.tableHeadRow}>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {i18n.t("tags.table.name")}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {i18n.t("tags.table.tickets")}
                </TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  {i18n.t("tags.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <TableRow key={tag.id}>
                  <TableCell align="center">
                    <Chip
                      variant="outlined"
                      className={classes.chip}
                      style={{
                        backgroundColor: tag.color,
                        color: "white",
                      }}
                      label={tag.name}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">{tag.ticketsCount}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditTag(tag)}
                    >
                      <FiEdit style={{ color: "#000" }} /> {/* Definir a cor como preto */}
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setConfirmModalOpen(true);
                        setDeletingTag(tag);
                      }}
                      className={classes.deleteIcon}
                    >
                      <FiTrash />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </TableBody> 
          </Table>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Tags;