// src/components/StatusPill/index.js

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStatusPillStyles = makeStyles(() => ({
  onlinePill: {
    backgroundColor: "#E6FFE6", // Verde claro para Online
    color: "#228B22", // Verde escuro
    borderRadius: 12,
    padding: "4px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  offlinePill: {
    backgroundColor: "#FFE6E6", // Vermelho claro para Offline
    color: "#D32F2F", // Vermelho escuro
    borderRadius: 12,
    padding: "4px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  unknownPill: {
    backgroundColor: "#F0F0F0", // Cinza claro
    color: "#777777", // Cinza escuro
    borderRadius: 12,
    padding: "4px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
  },
}));

const StatusPill = ({ status }) => {
  const classes = useStatusPillStyles();
  const normalized = status?.toLowerCase();

  if (!status) return <span>-</span>;

  if (normalized === "online") {
    return <span className={classes.onlinePill}>Online</span>;
  } else if (normalized === "offline") {
    return <span className={classes.offlinePill}>Offline</span>;
  } else {
    return <span className={classes.unknownPill}>{status}</span>;
  }
};

StatusPill.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusPill;