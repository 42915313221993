import React, { useState, useEffect } from "react";
import qs from "query-string";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import usePlans from "../../hooks/usePlans";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/logo2.png";
import { i18n } from "../../translate/i18n";
import "./style.css";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import WhatsAppIcon from "@material-ui/icons/WhatsApp"; // Ícone do WhatsApp

import toast from "../../errors/toast";

import moment from "moment";

// Estilo personalizado
const useStyles = makeStyles((theme) => ({
  formControl: {
    borderRadius: "8px", // Arredondando as bordas do FormControl
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Bordas arredondadas para o Select
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: "#f9fafb",
      padding: "0 4px", // Ajuste do padding para alinhar o label
      borderRadius: "8px",
      transform: "translate(14px, -6px) scale(0.75)", // Ajuste da posição do label
    },
  },
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Borda arredondada
      border: "2px solid #A200FF", // Borda visível
      height: "44px", // Altura do campo
      backgroundColor: "#fff", // Fundo branco
      display: "flex",
      alignItems: "center", // Centraliza verticalmente
      position: "relative", // Para controle da posição do label
    },
    "& .MuiInputLabel-root": {
      color: "#A200FF", // Cor do label
      textAlign: "center", // Centraliza o texto da label
      position: "absolute", // Posiciona o label dentro do campo
      top: "50%", // Centraliza verticalmente
      left: "14px", // Alinha o label à esquerda
      transform: "translateY(-50%)", // Corrige o alinhamento vertical
      pointerEvents: "none", // Impede interação com a label
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none", // Remove a borda padrão
    },
    "& .MuiSelect-icon": {
      color: "#A200FF", // Cor do ícone de seta
    },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff", // Fundo branco
      fontSize: "14px", // Ajuste do tamanho da fonte
    },
  },
}));

// Validação do Formulário
const UserSchema = Yup.object().shape({
  name: Yup.string().min(2, "Nome muito curto!").max(50, "Nome muito longo!").required("Campo obrigatório"),
  password: Yup.string().min(5, "Senha muito curta!").max(50, "Senha muito longa!").required("Campo obrigatório"),
  email: Yup.string().email("E-mail inválido").required("Campo obrigatório"),
  phone: Yup.string().required("Campo obrigatório"),
  planId: Yup.string().required("Campo obrigatório"),
});
const SignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  let companyId = null;

  // URL do WhatsApp com um número e mensagem de exemplo
  const whatsappLink = "https://wa.me/5584998400790?text=Olá!%20Gostaria%20de%20saber%20mais%20sobre%20os%20planos.";

  // Recupera o companyId da URL
  const params = qs.parse(window.location.search);
  if (params.companyId !== undefined) {
    companyId = params.companyId;
  }

  // Estado inicial dos valores do formulário
  const initialState = {
    name: "",
    email: "",
    phone: "",
    password: "",
    planId: "",
  };

  const [user, setUser] = useState(initialState);
  const [plans, setPlans] = useState([]);
  const dueDate = moment().add(3, "day").format();

  // Função para renderizar o tempo do plano
  const renderPlansTime = (selectedPlan) => {
    switch (selectedPlan.PlansTime) {
      case 1:
        return "MENSAL";
      case 3:
        return "TRIMESTRAL";
      case 6:
        return "SEMESTRAL";
      case 12:
        return "ANUAL";
      default:
        return "Desconhecido";
    }
  };

  // Função para o envio do cadastro
  const handleSignUp = async (values) => {
    const selectedPlan = plans.find((plan) => plan.id === values.planId);
    const planTime = selectedPlan ? renderPlansTime(selectedPlan) : "Desconhecido";

    // Atribui o plano e outros dados necessários
    Object.assign(values, { recurrence: planTime, dueDate, status: "t", campaignsEnabled: true });

    try {
      // Envia para o backend
      await openApi.post("/companies/cadastro", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login"); // Redireciona após sucesso
    } catch (err) {
      console.log(err);
      toastError(err); // Exibe erro se algo der errado
    }
  };

  // Função para buscar os planos
  const { list: listPlans } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const list = await listPlans();
      setPlans(list); // Armazena os planos recebidos
    }
    fetchData();
  }, []);

  return (
    <div
      className="geral-signup"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f9fafb",
      }}
    >
      <div className={"container-signup"} style={{ display: "flex", width: "100%", maxWidth: "1200px" }}>
        <div
          className={"paper"}
          style={{
            padding: "40px",
            borderRadius: "16px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            flex: 1,
          }}
        >
          {/* Título e Subtítulo */}
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#1A202C",
              marginBottom: "8px",
            }}
          >
            Cadastre-se
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              textAlign: "center",
              fontSize: "16px",
              color: "#6B7280",
              marginBottom: "20px",
            }}
          >
            Comece seu <b>teste GRATUITO</b> de 3 dias do CRM da WhatsNeed!
          </Typography>

          <Formik
            initialValues={user}
            enableReinitialize={true}
            validationSchema={UserSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSignUp(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="name"
                      variant="outlined"
                      placeholder="Seu nome"
                      fullWidth
                      required
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "14px",
                          height: "44px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="email"
                      type="email"
                      variant="outlined"
                      placeholder="Seu e-mail"
                      fullWidth
                      required
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "14px",
                          height: "44px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="phone"
                      type="tel"
                      variant="outlined"
                      placeholder="Seu número de telefone"
                      fullWidth
                      required
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "14px",
                          height: "44px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="password"
                      type="password"
                      variant="outlined"
                      placeholder="Sua senha"
                      fullWidth
                      required
                      InputProps={{
                        style: {
                          borderRadius: "8px",
                          fontSize: "14px",
                          height: "44px",
                        },
                      }}
                    />
                  </Grid>

                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        margin="dense"
                        name="instagram"
                        variant="outlined"
                        placeholder="@Instagram"
                        fullWidth
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            fontSize: "14px",
                            height: "44px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        className={classes.formControl} // Aplicando a mesma classe de estilo
                      >
                        <InputLabel>Funcionários</InputLabel>
                        <Field
                          as={Select}
                          name="companySize"
                          style={{
                            borderRadius: "8px",
                            fontSize: "14px",
                            height: "44px",

                          }}
                        >
                          <MenuItem value="1-10 funcionários">1-10 funcionários</MenuItem>
                          <MenuItem value="11-50 funcionários">10-20 funcionários</MenuItem>
                          <MenuItem value="11-50 funcionários">20-30 funcionários</MenuItem>
                          <MenuItem value="11-50 funcionários">30-40 funcionários</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      className={classes.formControl} // Aplicando a mesma classe de estilo
                    >
                      <InputLabel>Plano</InputLabel>
                      <Field
                        as={Select}
                        name="planId"
                        style={{
                          borderRadius: "8px",
                          fontSize: "14px",
                          height: "44px",
                        }}
                      >
                        {plans.map((plan) => (
                          <MenuItem key={plan.id} value={plan.id}>
                            {plan.name} - {plan.connections} WhatsApps - {plan.users} Usuários - R$ {plan.value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        backgroundColor: "#a200ff",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "14px",
                        height: "44px",
                        borderRadius: "8px",
                        textTransform: "none",
                      }}
                      fullWidth
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Cadastrando..." : "Cadastrar"}
                    </Button>
                  </Grid>

                  {/* Link de Login */}
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Link href="#" variant="body2" component={RouterLink} to="/login">
                      Já tem uma conta? Entre!
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <Box mt={5}></Box>
      </div>
      <div className={"container-img-signup"}>
        <div className="img-signup"></div>
      </div>
      <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-float-button">
        <WhatsAppIcon fontSize="large" />
      </a>
    </div>
  );
};

export default SignUp;