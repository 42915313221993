// src/components/QrcodeModal/index.js

import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { isNil, head } from "lodash";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  FormControlLabel,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import moment from "moment";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal from "../ConfirmationModal";
import { SocketContext } from "../../context/Socket/SocketContext"; // Adicionado para o contexto do socket

// Definir o Switch personalizado no estilo da Apple
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
  },
  
  thumb: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.grey[50],
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

// Definir os estilos usando makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    borderRadius: "8px", // Arredondando as bordas do FormControl
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Bordas arredondadas para o Select
    },
    "& .MuiInputLabel-outlined": {
      backgroundColor: "white",
      padding: "0 4px", // Ajuste do padding para alinhar o label
      borderRadius: "8px",
      transform: "translate(14px, -6px) scale(0.75)", // Ajuste da posição do label
    },
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  dialogPaper: {
    borderRadius: "13px", // Bordas arredondadas para 13px
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    maxWidth: "50%",  // Limitar a largura para 90% da tela, para evitar overflow    width: "100%",
    overflowX: "hidden", // Evita overflow horizontal
    margin: "auto", // Centraliza o modal


  },


  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap", // Permite que os itens quebrem a linha se necessário
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  instructions: {
    marginRight: theme.spacing(4),
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    flex: "1 1 300px", // Flex-grow, flex-shrink e flex-basis para responsividade
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },

  title: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    color: "#6F2CC0",
    fontFamily: "Montserrat, sans-serif",
    wordBreak: "break-word", // Quebra de linha para textos longos
  },

  step: {
    fontSize: "16px",
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    wordBreak: "break-word", // Quebra de linha para textos longos
  },

  icon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: "20px",
    height: "20px",
    fill: "#6F2CC0",
    flexShrink: 0, // Evita que o ícone encolha
    verticalAlign: "middle", // Alinha verticalmente com o texto
  },

  qrCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1 200px", // Flex-grow, flex-shrink e flex-basis para responsividade
    maxWidth: "100%", // Garante que não exceda a largura do container
  },

  qrCode: {
    borderRadius: "12px",
    padding: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#f9f9f9",
    maxWidth: "100%", // Garante que o QR Code não exceda o container
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  waitingText: {
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    fontSize: "16px",
    textAlign: "center",
    width: "100%", // Centraliza o texto
  },

  instructionWithIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1), // Espaçamento entre instruções
  },

  instructionText: {
    marginRight: theme.spacing(1),
  },

  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 8, // Bordas arredondadas para os campos de preenchimento
    },
  },

  button: {
    borderRadius: "8px",
    textTransform: "none",
    fontFamily: "Montserrat, sans-serif",
  },

  // Estilização personalizada para os botões
  cancelButton: {
    borderRadius: "8px",
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },

  submitButton: {
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  attachButton: {
    borderRadius: "8px",
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
    marginRight: theme.spacing(1),
  },

  importMessage: {
    marginTop: theme.spacing(2),
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Obrigatório"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta",
    timeSendQueue: 0,
    sendIdQueue: 0,
    expiresInactiveMessage: "",
    expiresTicket: 0,
    timeUseBotQueues: 0,
    maxUseBotQueues: 3,
    attachment: null,
    mediaName: "",
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [enableImportMessage, setEnableImportMessage] = useState(false);
  const [importOldMessagesGroups, setImportOldMessagesGroups] = useState(false);
  const [closedTicketsPostImported, setClosedTicketsPostImported] = useState(false);
  const [importOldMessages, setImportOldMessages] = useState(moment().add(-1, "days").format("YYYY-MM-DDTHH:mm"));
  const [importRecentMessages, setImportRecentMessages] = useState(moment().add(-1, "minutes").format("YYYY-MM-DDTHH:mm"));
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const attachmentFile = useRef(null);

  const socketManager = useContext(SocketContext); // Usando o contexto do socket

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/prompt");
        setPrompts(data.prompts);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [whatsAppId]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);

        setWhatsApp(data);
        data.promptId ? setSelectedPrompt(data.promptId) : setSelectedPrompt(null);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        if (!isNil(data?.importOldMessages)) {
          setEnableImportMessage(true);
          setImportOldMessages(data?.importOldMessages);
          setImportRecentMessages(data?.importRecentMessages);
          setClosedTicketsPostImported(data?.closedTicketsPostImported);
          setImportOldMessagesGroups(data?.importOldMessagesGroups);
        }
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const handleEnableImportMessage = (e) => {
    setEnableImportMessage(e.target.checked);
  };

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = {
      ...values,
      queueIds: selectedQueueIds,
      promptId: selectedPrompt ? selectedPrompt : null,
      importOldMessages: enableImportMessage ? importOldMessages : null,
      importRecentMessages: enableImportMessage ? importRecentMessages : null,
      importOldMessagesGroups: importOldMessagesGroups ? importOldMessagesGroups : null,
      closedTicketsPostImported: closedTicketsPostImported ? closedTicketsPostImported : null,
    };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    try {
      if (whatsAppId) {
        if (whatsAppId && enableImportMessage && whatsApp?.status === "CONNECTED") {
          try {
            setWhatsApp({ ...whatsApp, status: "qrcode" });
            await api.delete(`/whatsappsession/${whatsApp.id}`);
          } catch (err) {
            toastError(err);
          }
        }

        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);

        if (values.attachment && values.attachment.size) {
          const formData = new FormData();
          formData.append("file", values.attachment);

          await api.post(`/whatsapp/${whatsAppId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/whatsapp", whatsappData);

        if (values.attachment) {
          const formData = new FormData();
          formData.append("file", values.attachment);

          await api.post(`/whatsapp/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleChangeQueue = (e) => {
    setSelectedQueueIds(e);
    setSelectedPrompt(null);
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
    setSelectedQueueIds([]);
  };

  const handleClose = () => {
    onClose();
    setEnableImportMessage(false);
    setWhatsApp(initialState);
  };

  const removeMedia = async () => {
    await api.delete(`/whatsapp/${whatsAppId}/media-upload`);
    handleClose();
  }

  const handleAttach = (e, setFile) => {
    const file = head(e.target.files);

    console.log(file);

    if (file) {
      setFile('attachment', file);
    }
  }

  return (
    <div className={classes.root}>

      <ConfirmationModal
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={removeMedia}
        title={i18n.t("whatsappModal.confirmation.title")}
      >
        {i18n.t("whatsappModal.confirmation.message")}
      </ConfirmationModal>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
        PaperProps={{
          className: classes.dialogPaper, // Aplicando a classe de estilos
        }}
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t("whatsappModal.title.edit")
            : i18n.t("whatsappModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Grid spacing={2} container>
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.name")}
                        autoFocus
                        name="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        className={`${classes.textField}`}
                      />
                    </Grid>
                    <Grid style={{ paddingTop: 15 }} item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Field
                            as={IOSSwitch}
                            name="isDefault"
                            checked={values.isDefault}
                          />
                        }
                        label={i18n.t("whatsappModal.form.default")}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.importMessage}>
                  <div className={classes.multFieldLine}>
                    <FormControlLabel
                      style={{ marginRight: 7, color: "gray" }}
                      label={i18n.t("whatsappModal.form.importOldMessagesEnable")}
                      labelPlacement="end"
                      control={
                        <IOSSwitch
                          size="medium"
                          checked={enableImportMessage}
                          onChange={handleEnableImportMessage}
                          name="importOldMessagesEnable"
                        />
                      }
                    />

                    {enableImportMessage ? (
                      <>
                        <FormControlLabel
                          style={{ marginRight: 7, color: "gray" }}
                          label={i18n.t(
                            "whatsappModal.form.importOldMessagesGroups"
                          )}
                          labelPlacement="end"
                          control={
                            <IOSSwitch
                              size="medium"
                              checked={importOldMessagesGroups}
                              onChange={(e) =>
                                setImportOldMessagesGroups(e.target.checked)
                              }
                              name="importOldMessagesGroups"
                            />
                          }
                        />

                        <FormControlLabel
                          style={{ marginRight: 7, color: "gray" }}
                          label={i18n.t(
                            "whatsappModal.form.closedTicketsPostImported"
                          )}
                          labelPlacement="end"
                          control={
                            <IOSSwitch
                              size="medium"
                              checked={closedTicketsPostImported}
                              onChange={(e) =>
                                setClosedTicketsPostImported(e.target.checked)
                              }
                              name="closedTicketsPostImported"
                            />
                          }
                        />
                      </>) : null}
                  </div>

                  {enableImportMessage ? (
                    <Grid style={{ marginTop: 18 }} container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          fullWidth
                          as={TextField}
                          label={i18n.t("whatsappModal.form.importOldMessages")}
                          type="datetime-local"
                          name="importOldMessages"
                          inputProps={{
                            max: moment()
                              .add(0, "minutes")
                              .format("YYYY-MM-DDTHH:mm"),
                            min: moment()
                              .add(-2, "years")
                              .format("YYYY-MM-DDTHH:mm"),
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.importOldMessages &&
                            Boolean(errors.importOldMessages)
                          }
                          helperText={
                            touched.importOldMessages && errors.importOldMessages
                          }
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          value={moment(importOldMessages).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) => {
                            setImportOldMessages(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          fullWidth
                          as={TextField}
                          label={i18n.t("whatsappModal.form.importRecentMessages")}
                          type="datetime-local"
                          name="importRecentMessages"
                          inputProps={{
                            max: moment()
                              .add(0, "minutes")
                              .format("YYYY-MM-DDTHH:mm"),
                            min: moment(importOldMessages).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={
                            touched.importRecentMessages &&
                            Boolean(errors.importRecentMessages)
                          }
                          helperText={
                            touched.importRecentMessages && errors.importRecentMessages
                          }
                          variant="outlined"
                          margin="dense"
                          className={classes.textField}
                          value={moment(importRecentMessages).format(
                            "YYYY-MM-DDTHH:mm"
                          )}
                          onChange={(e) => {
                            setImportRecentMessages(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                  ) : null}
                </div>
                {enableImportMessage && (
                  <Typography variant="body2" style={{ color: "red" }}>
                    {i18n.t("whatsappModal.form.importAlert")}
                  </Typography>
                )}
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.greetingMessage")}
                    type="greetingMessage"
                    multiline
                    rows={4}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.complationMessage")}
                    type="complationMessage"
                    multiline
                    rows={4}
                    fullWidth
                    name="complationMessage"
                    error={
                      touched.complationMessage &&
                      Boolean(errors.complationMessage)
                    }
                    helperText={
                      touched.complationMessage && errors.complationMessage
                    }
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.outOfHoursMessage")}
                    type="outOfHoursMessage"
                    multiline
                    rows={4}
                    fullWidth
                    name="outOfHoursMessage"
                    error={
                      touched.outOfHoursMessage &&
                      Boolean(errors.outOfHoursMessage)
                    }
                    helperText={
                      touched.outOfHoursMessage && errors.outOfHoursMessage
                    }
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.ratingMessage")}
                    type="ratingMessage"
                    multiline
                    rows={4}
                    fullWidth
                    name="ratingMessage"
                    error={
                      touched.ratingMessage && Boolean(errors.ratingMessage)
                    }
                    helperText={touched.ratingMessage && errors.ratingMessage}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t("queueModal.form.token")}
                    type="token"
                    fullWidth
                    name="token"
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                </div>

                {/* SELETOR FILAS */}
                <FormControl
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  className={classes.formControl} // Aplicando a mesma classe de estilo
                >
                  
                  <QueueSelect
                    selectedQueueIds={selectedQueueIds}
                    onChange={(selectedIds) => handleChangeQueue(selectedIds)}
                    labelId="sendIdQueue-selection-label" // Associando o label ao Select
                    label={i18n.t("whatsappModal.form.sendIdQueue")} // Definindo o label do Select
                  />
                </FormControl>

                {/* Campo "Prompt" */}
                <FormControl
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  className={classes.textField}
                >
                  <InputLabel id="dialog-select-prompt-label">
                    {i18n.t("whatsappModal.form.prompt")}
                  </InputLabel>
                  <Select
                    labelId="dialog-select-prompt-label"
                    id="dialog-select-prompt"
                    name="promptId"
                    value={selectedPrompt || ""}
                    onChange={handleChangePrompt}
                    label={i18n.t("whatsappModal.form.prompt")}
                    fullWidth
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {prompts.map((prompt) => (
                      <MenuItem key={prompt.id} value={prompt.id}>
                        {prompt.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div>
                  <Typography variant="h6" gutterBottom>
                    {i18n.t("whatsappModal.form.queueRedirection")}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {i18n.t("whatsappModal.form.queueRedirectionDesc")}
                  </Typography>
                  <Grid spacing={2} container>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        fullWidth
                      >
                        <InputLabel id="sendIdQueue-selection-label">
                          {i18n.t("whatsappModal.form.sendIdQueue")}
                        </InputLabel>
                        <Field
                          as={Select}
                          name="sendIdQueue"
                          id="sendIdQueue"
                          label={i18n.t("whatsappModal.form.sendIdQueue")}
                          placeholder={i18n.t("whatsappModal.form.sendIdQueue")}
                          labelId="sendIdQueue-selection-label"
                        >
                          <MenuItem value={0}>&nbsp;</MenuItem>
                          {queues.map(queue => (
                            <MenuItem
                              key={queue.id}
                              value={queue.id}
                            >
                              {queue.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.timeSendQueue")}
                        fullWidth
                        name="timeSendQueue"
                        variant="outlined"
                        margin="dense"
                        error={touched.timeSendQueue && Boolean(errors.timeSendQueue)}
                        helperText={touched.timeSendQueue && errors.timeSendQueue}
                        className={classes.textField}
                      />
                    </Grid>

                  </Grid>
                  <Grid spacing={2} container>
                    {/* QUANTIDADE MÁXIMA DE VEZES QUE O CHATBOT VAI SER ENVIADO */}
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.maxUseBotQueues")}
                        fullWidth
                        name="maxUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        error={touched.maxUseBotQueues && Boolean(errors.maxUseBotQueues)}
                        helperText={touched.maxUseBotQueues && errors.maxUseBotQueues}
                        className={classes.textField}
                      />
                    </Grid>
                    {/* TEMPO PARA ENVIO DO CHATBOT */}
                    <Grid item xs={12} sm={6}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.timeUseBotQueues")}
                        fullWidth
                        name="timeUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        error={touched.timeUseBotQueues && Boolean(errors.timeUseBotQueues)}
                        helperText={touched.timeUseBotQueues && errors.timeUseBotQueues}
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                  <Grid spacing={2} container>
                    {/* ENCERRAR CHATS ABERTOS APÓS X HORAS */}
                    <Grid item xs={12} sm={12}>
                      <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.expiresTicket")}
                        fullWidth
                        name="expiresTicket"
                        variant="outlined"
                        margin="dense"
                        error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                        helperText={touched.expiresTicket && errors.expiresTicket}
                        className={classes.textField}
                      />
                    </Grid>
                  </Grid>
                  {/* MENSAGEM POR INATIVIDADE*/}
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("whatsappModal.form.expiresInactiveMessage")}
                      multiline
                      rows={4}
                      fullWidth
                      name="expiresInactiveMessage"
                      error={touched.expiresInactiveMessage && Boolean(errors.expiresInactiveMessage)}
                      helperText={touched.expiresInactiveMessage && errors.expiresInactiveMessage}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  </div>
                </div>


                {/* Se houver anexos ou mídia, exibir os botões */}
                <Grid item xs={12}>
                  <Button
                    startIcon={<AttachFileIcon />}
                    variant="contained"
                    color="info"
                    className={`${classes.button} ${classes.attachButton}`}
                  >
                    {values?.attachment ? values?.attachment?.name : whatsApp?.mediaName}
                  </Button>
                  <IconButton
                    onClick={() => values.attachment?.size ? setFieldValue('attachment', null) : setConfirmationOpen(true)}
                    color="secondary"
                  >
                    <DeleteOutlineIcon color="secondary" />
                  </IconButton>
                </Grid>

                {/* Input de arquivo escondido */}
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    ref={attachmentFile}
                    onChange={(e) => handleAttach(e, setFieldValue)}
                  />
                </div>


              </DialogContent>
              <DialogActions>

                {/* Botão para anexar arquivo, exibido apenas se não houver anexo ou mídia */}
                {!values.attachment && !values.mediaName && (
                  <Button
                    color="primary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="contained"
                    className={`${classes.button} ${classes.attachButton}`}
                  >
                    {i18n.t("quickMessages.buttons.attach")}
                  </Button>

                )}

                {/* Botão de Cancelar */}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={`${classes.button} ${classes.cancelButton}`}
                >
                  {i18n.t("whatsappModal.buttons.cancel")}
                </Button>

                {/* Botão de Submit */}
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.button} ${classes.submitButton} ${classes.btnWrapper}`}
                >
                  {whatsAppId
                    ? i18n.t("whatsappModal.buttons.okEdit")
                    : i18n.t("whatsappModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);