import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Modal,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import useHelps from "../../hooks/useHelps";

const useStyles = makeStyles((theme) => ({
  /** Fundo mais claro, ocupando a tela toda */
  outerContainer: {
    backgroundColor: "#f9f9f9", // Tom de cinza bem claro
    minHeight: "100vh",
    padding: theme.spacing(4),
    boxSizing: "border-box",
  },

  /** Texto centralizado no topo (hero) */
  heroSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    color: "#444", // Tom de cinza para texto
  },
  heroTitle: {
    fontWeight: 700,
    fontSize: "2.2rem",
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  heroSubtitle: {
    fontSize: "1rem",
    textAlign: "center",
    maxWidth: 600,
    marginBottom: theme.spacing(4),
    color: "#666",
  },

  /** Barra de busca ao centro */
  searchBarContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(5),
    width: "100%",
  },
  searchInput: {
    width: "400px",
    maxWidth: "70%",
    backgroundColor: "#fff",
    borderRadius: 32,
    padding: theme.spacing(1, 2),
    outline: "none",
    border: "1px solid #ddd",  // Borda padrão
    boxShadow: theme.shadows[1], // Leve sombra
    "& .MuiOutlinedInput-root": {
      borderRadius: 32,
      border: "none",  // Remove a borda extra
    },
    "&:focus": {
      border: "1px solid #6F2CC0",  // A cor da borda quando está em foco
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",  // Remova a borda adicional quando estiver em foco
    },
  },

  /** Container dos cards */
  cardsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr))",
    gap: theme.spacing(3),
    width: "100%",
  },

  /** Card individual */
  card: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    cursor: "pointer",
    boxShadow: theme.shadows[1],
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows[4],
    },
  },

  /** Imagem (thumbnail) do YouTube */
  videoThumbnail: {
    width: "100%",
    height: 160,
    objectFit: "cover",
  },

  /** Tag de categoria / Pill no topo do card */
  categoryPill: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: "#EDEBFF",
    color: "#6F2CC0",
    borderRadius: 16,
    padding: theme.spacing(0.5, 1),
    fontSize: "0.75rem",
    fontWeight: 600,
    boxShadow: theme.shadows[1],
  },

  /** Tag de duração do vídeo no canto */
  durationPill: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: "rgba(0,0,0,0.75)",
    color: "#fff",
    borderRadius: 4,
    padding: theme.spacing(0.5, 1),
    fontWeight: "bold",
    fontSize: "0.8rem",
  },

  /** Container do texto dentro do card */
  cardTextContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  cardTitle: {
    fontSize: "1rem",
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: "#333",
  },
  cardDescription: {
    fontSize: "0.9rem",
    color: "#555",
    lineHeight: 1.4,
  },

  /** Modal do vídeo */
  videoModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoModalContent: {
    outline: "none",
    width: "90%",
    maxWidth: 1024,
    aspectRatio: "16/9",
    position: "relative",
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    boxShadow: theme.shadows[5],
  },
}));

const Helps = () => {
  const classes = useStyles();
  const { list } = useHelps();

  const [records, setRecords] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);

  /** Campo de busca (opcional) */
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      const helps = await list();
      setRecords(helps);
    }
    fetchData();
  }, [list]);

  const openVideoModal = (video) => {
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  /** Modal do vídeo do YouTube */
  const renderVideoModal = () => (
    <Modal
      open={Boolean(selectedVideo)}
      onClose={closeVideoModal}
      className={classes.videoModal}
    >
      <div className={classes.videoModalContent}>
        {selectedVideo && (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            src={`https://www.youtube.com/embed/${selectedVideo}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </Modal>
  );

  /** Renderização dos cards */
  const renderHelps = () => {
    // Filtrar com base na barra de pesquisa, se quiser
    const filteredRecords = records.filter((item) => {
      const title = item.title?.toLowerCase() || "";
      const desc = item.description?.toLowerCase() || "";
      return (
        title.includes(searchTerm.toLowerCase()) ||
        desc.includes(searchTerm.toLowerCase())
      );
    });

    return (
      <div className={classes.cardsContainer}>
        {filteredRecords.map((record, idx) => {
          // Extrai o ID do vídeo, caso esteja no formato "abcd123?xxx"
          const videoId = record.video.split("?")[0];
          const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

          const category = record.category || "";
          const duration = record.duration || "";

          return (
            <Paper
              key={idx}
              className={classes.card}
              onClick={() => openVideoModal(record.video)}
            >
              {category && (
                <div className={classes.categoryPill}>{category}</div>
              )}

              <img
                src={thumbnailUrl}
                alt="Thumbnail"
                className={classes.videoThumbnail}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://via.placeholder.com/320x180?text=No+Thumbnail";
                }}
              />

              {duration && (
                <div className={classes.durationPill}>{duration}</div>
              )}

              <div className={classes.cardTextContainer}>
                <Typography className={classes.cardTitle}>
                  {record.title}
                </Typography>
                <Typography className={classes.cardDescription}>
                  {record.description}
                </Typography>
              </div>
            </Paper>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.outerContainer}>
      {/* Seção do Título / Subtítulo */}
      <div className={classes.heroSection}>
        <Typography variant="h1" className={classes.heroTitle}>
          Central de Ajuda
        </Typography>
        <Typography className={classes.heroSubtitle}>
          Encontre tutoriais e aprenda a usar todas as funcionalidades do sistema
        </Typography>

        {/* Barra de busca */}
        <div className={classes.searchBarContainer}>
          <TextField
            variant="outlined"
            placeholder="Buscar tutoriais..."
            className={classes.searchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      {/* Grid de Cards */}
      {renderHelps()}

      {/* Modal do vídeo */}
      {renderVideoModal()}
    </div>
  );
};

export default Helps;