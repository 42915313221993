import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useCallback
} from "react";


import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Grid
} from "@material-ui/core";

// Ícones Feather
import { FiEdit, FiTrash } from "react-icons/fi";

import SearchIcon from "@material-ui/icons/Search";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import QuickMessageDialog from "../../components/QuickMessageDialog";
import ConfirmationModal from "../../components/ConfirmationModal";

import toast from "../../errors/toast"; // Importando o toast que agora usa o toastSuccess personalizado
import toastError from "../../errors/toastError"; // Importando o toastError



import { isArray } from "lodash";
import { SocketContext } from "../../context/Socket/SocketContext";
import { AuthContext } from "../../context/Auth/AuthContext";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";


// =========================
// 1. REDUCER (inalterado)
// =========================
const reducer = (state, action) => {
  if (action.type === "LOAD_QUICKMESSAGES") {
    const quickmessages = action.payload;
    const newQuickmessages = [];
    if (isArray(quickmessages)) {
      quickmessages.forEach((msg) => {
        const idx = state.findIndex((u) => u.id === msg.id);
        if (idx !== -1) {
          state[idx] = msg;
        } else {
          newQuickmessages.push(msg);
        }
      });
    }
    return [...state, ...newQuickmessages];
  }

  if (action.type === "UPDATE_QUICKMESSAGES") {
    const quickemessage = action.payload;
    const idx = state.findIndex((u) => u.id === quickemessage.id);
    if (idx !== -1) {
      state[idx] = quickemessage;
      return [...state];
    } else {
      return [quickemessage, ...state];
    }
  }

  if (action.type === "DELETE_QUICKMESSAGE") {
    const quickemessageId = action.payload;
    const idx = state.findIndex((u) => u.id === quickemessageId);
    if (idx !== -1) {
      state.splice(idx, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  return state;
};

/* -----------------------------------------------------
   Estilos das pílulas para o status (ativo, inativo)
----------------------------------------------------- */
const useStatusStyles = makeStyles(() => ({
  activePill: {
    backgroundColor: "#E6FFE6",
    color: "#2DB53B",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize"
  },
  inactivePill: {
    backgroundColor: "#FFE6E6",
    color: "#E03D3D",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize"
  },
  unknownPill: {
    backgroundColor: "#F0F0F0",
    color: "#777",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize"
  }
}));

const StatusPill = ({ status }) => {
  const classes = useStatusStyles();
  const normalized = status?.toLowerCase();
  if (!status) return <span>-</span>;

  if (normalized === "ativo") {
    return <span className={classes.activePill}>{status}</span>;
  } else if (normalized === "inativo") {
    return <span className={classes.inactivePill}>{status}</span>;
  } else {
    return <span className={classes.unknownPill}>{status}</span>;
  }
};

/* -----------------------------------------------------
   Estilos principais
----------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0
  },
  headerSubtitle: {
    margin: 0,
    color: "#8E8E8E",
    fontSize: "0.95rem"
  },

  // Cartão que envolve a tabela
  mainPaper: {
    border: "1px solid #E3E3E3",    // cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles
  },

  // Ações de edição/remoção
  actionButton: {
    margin: theme.spacing(0, 0.5)
  },
  editIcon: {
    color: "#000"
  },
  deleteIcon: {
    color: "#E03D3D"
  },

  // Tabela
  tableRoot: {
    borderCollapse: "separate",
    borderSpacing: 0
  },
  // Arredondar cabeçalho (cantos superiores)
  tableHeadRow: {
    backgroundColor: "#FAFAFA",
    "& th:first-child": {
      borderTopLeftRadius: 8
    },
    "& th:last-child": {
      borderTopRightRadius: 8
    }
  },
  tableHeadCell: {
    color: "#6B6B6B",
    fontWeight: "bold",
    borderBottom: "none",
    fontSize: "0.9rem"
  },
  tableCell: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "0.875rem",
    "&:first-child": {
      borderRadius: "0 0 0 8px"
    },
    "&:last-child": {
      borderRadius: "0 0 8px 0"
    }
  },

  // Campo de busca
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: 220,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d8d8d8"
    }
  },

  // Botão "Adicionar"
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,

    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },

  // Badges "Ativo"/"Inativo" (se global = true)
  badgeAtivo: {
    backgroundColor: "#DBFFE2",
    color: "#357A38",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize"
  },
  badgeInativo: {
    backgroundColor: "#FFE6E6",
    color: "#E03D3D",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize"
  }

}));

// =========================
// 3. COMPONENTE PRINCIPAL
// =========================
const Quickemessages = () => {
  const classes = useStyles();

  // ESTADOS
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedQuickemessage, setSelectedQuickemessage] = useState(null);
  const [deletingQuickemessage, setDeletingQuickemessage] = useState(null);
  const [quickemessageModalOpen, setQuickMessageDialogOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [quickemessages, dispatch] = useReducer(reducer, []);

  // CONTEXT
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const socketManager = useContext(SocketContext);

  // Reseta lista quando altera busca
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  // Busca com debounce
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchQuickemessages();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  // Websocket
  useEffect(() => {
    const companyId = user.companyId;
    const socket = socketManager.getSocket(companyId);

    socket.on(`company${companyId}-quickemessage`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUICKMESSAGES", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUICKMESSAGE", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager, user.companyId]);

  // Função para buscar do back-end
  const fetchQuickemessages = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const { data } = await api.get("/quick-messages/list", {
        params: { companyId, userId: user.id }
      });
      dispatch({ type: "LOAD_QUICKMESSAGES", payload: data });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  // Abre modal de criar/editar
  const handleOpenQuickMessageDialog = () => {
    setSelectedQuickemessage(null);
    setQuickMessageDialogOpen(true);
  };

  const handleCloseQuickMessageDialog = () => {
    setSelectedQuickemessage(null);
    setQuickMessageDialogOpen(false);
    fetchQuickemessages();
  };

  // Pesquisa
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  // Editar
  const handleEditQuickemessage = (quickemessage) => {
    setSelectedQuickemessage(quickemessage);
    setQuickMessageDialogOpen(true);
  };

  // Excluir
  const handleDeleteQuickemessage = async (quickemessageId) => {
    try {
      await api.delete(`/quick-messages/${quickemessageId}`);
      toast.success(i18n.t("Atalho apagado com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setDeletingQuickemessage(null);
    setSearchParam("");
    setPageNumber(1);
    fetchQuickemessages();
    dispatch({ type: "RESET" });
  };

  // Paginação infinita
  const loadMore = () => {
    setPageNumber((prev) => prev + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // RENDER
  return (
    <MainContainer>
      {/* MODAL de confirmação de exclusão */}
      <ConfirmationModal
        title={
          deletingQuickemessage &&
          `${i18n.t("quickMessages.confirmationModal.deleteTitle")} ${deletingQuickemessage.shortcode
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteQuickemessage(deletingQuickemessage.id)}
      >
        {i18n.t("quickMessages.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      {/* MODAL de criar/editar */}
      <QuickMessageDialog
        resetPagination={() => {
          setPageNumber(1);
          fetchQuickemessages();
        }}
        open={quickemessageModalOpen}
        onClose={handleCloseQuickMessageDialog}
        quickemessageId={selectedQuickemessage && selectedQuickemessage.id}
      />

      {/* Cabeçalho */}
      <MainHeader>
        <div>
          <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("quickMessages.title")}
          </Typography>
        </div>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("quickMessages.searchPlaceholder") || "Pesquisar..."}
            type="search"
            value={searchParam}
            onChange={handleSearch}  // Função chamada sempre que o usuário digita algo
            variant="outlined"
            size="small"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={handleOpenQuickMessageDialog}
          >
            {i18n.t("quickMessages.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* TABELA */}
      <Paper className={classes.mainPaper} onScroll={handleScroll}>
        <Table size="small" className={classes.tableRoot}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("quickMessages.table.shortcode")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("quickMessages.table.mediaName")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("quickMessages.table.status")}
              </TableCell>
              <TableCell align="center" className={classes.tableHeadCell}>
                {i18n.t("quickMessages.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {quickemessages.map((quickemessage) => {
              const isAtivo = quickemessage.geral === true;

              return (
                <TableRow key={quickemessage.id}>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                  >
                    {quickemessage.shortcode}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                  >
                    {quickemessage.mediaName ?? "Sem anexo"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                  >
                    {isAtivo ? (
                      <span className={classes.badgeAtivo}>Ativo</span>
                    ) : (
                      <span className={classes.badgeInativo}>Inativo</span>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                  >
                    {(profile === "admin" ||
                      profile === "supervisor" ||
                      (profile === "user" && !quickemessage.geral)) && (
                        <>
                          <IconButton
                            size="small"
                            className={classes.actionButton}
                            onClick={() => handleEditQuickemessage(quickemessage)}
                          >
                            <FiEdit className={classes.editIcon} />
                          </IconButton>
                          <IconButton
                            size="small"
                            className={classes.actionButton}
                            onClick={() => {
                              setConfirmModalOpen(true);
                              setDeletingQuickemessage(quickemessage);
                            }}
                          >
                            <FiTrash className={classes.deleteIcon} />
                          </IconButton>
                        </>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
            {loading && <TableRowSkeleton columns={4} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Quickemessages;