import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";
import { head } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";

// ==================
// Estilo do QuickMessageDialog
// ==================
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  // ***Novas Personalizações***
  dialogPaper: {
    borderRadius: "13px", // Bordas arredondadas do Dialog
  },
  // Botão principal (Salvar/Adicionar)
  button: {
    borderRadius: "12px",  // Bordas arredondadas nos botões
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    backgroundColor: "#6F2CC0",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },
  // Botão de Cancelar
  cancelButton: {
    borderRadius: "8px", // Mantido
    color: theme.palette.secondary.main,
    borderRadius: "12px",  // Bordas arredondadas nos botões
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
  },
  // Botão de Excluir (opcional)
  deleteButton: {
    backgroundColor: "F44336",
    color: "#transparent",
    border: "none",
    borderRadius: "50%",
    padding: "5px",
    "&:hover": {
      backgroundColor: "rgba(244, 67, 54, 0.1)",
    },
  },
  // Ícone de Excluir
  deleteIcon: {
    fontSize: "20px",
    color: "#F44336",
  },
  // Botão de Anexar
  attachmentButton: {
    borderRadius: "12px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    backgroundColor: "#fff",
    color: "#000",
    border: `1px solid #ccc`,
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
    "&:focus": {
      outline: "none",
      border: "1px solid #6F2CC0",
    },
  },
}));

// Validação
const AnnouncementSchema = Yup.object().shape({
  title: Yup.string().required("Obrigatório"),
  text: Yup.string().required("Obrigatório"),
});

const AnnouncementModal = ({ open, onClose, announcementId, reload }) => {
  const classes = useStyles();

  const initialState = {
    title: "",
    text: "",
    priority: 3,
    status: true,
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [announcement, setAnnouncement] = useState(initialState);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);

  // Carrega dados se houver ID
  useEffect(() => {
    (async () => {
      if (!announcementId) return;
      try {
        const { data } = await api.get(`/announcements/${announcementId}`);
        setAnnouncement((prevState) => ({ ...prevState, ...data }));
      } catch (err) {
        toastError(err);
      }
    })();
  }, [announcementId, open]);

  // Fecha o modal
  const handleClose = () => {
    setAnnouncement(initialState);
    setAttachment(null);
    onClose();
  };

  // Ao selecionar arquivo
  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) setAttachment(file);
  };

  // Salva (cria ou edita)
  const handleSaveAnnouncement = async (values) => {
    const announcementData = { ...values };
    try {
      if (announcementId) {
        // EDIT (se existe ID)
        await api.put(`/announcements/${announcementId}`, announcementData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/announcements/${announcementId}/media-upload`, formData);
        }
      } else {
        // ADD (se não tem ID)
        const { data } = await api.post("/announcements", announcementData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/announcements/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("announcements.toasts.success"));
      if (typeof reload === "function") reload();
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  // Exclui anexo
  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }
    if (announcement.mediaPath) {
      await api.delete(`/announcements/${announcement.id}/media-upload`);
      setAnnouncement((prev) => ({ ...prev, mediaPath: null }));
      toast.success(i18n.t("announcements.toasts.deleted"));
      if (typeof reload === "function") reload();
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("announcements.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("announcements.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title">
          {/* Verifica se há ID: se houver, é edição; se não, é adição */}
          {announcementId
            ? `${i18n.t("announcements.dialog.edit")}`
            : `${i18n.t("announcements.dialog.add")}`}
        </DialogTitle>

        {/* Campo de upload (invisível) */}
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            ref={attachmentFile}
            onChange={handleAttachmentFile}
          />
        </div>

        <Formik
          initialValues={announcement}
          enableReinitialize
          validationSchema={AnnouncementSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveAnnouncement(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  {/* Título */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("announcements.dialog.form.title")}
                      name="title"
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>

                  {/* Texto */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("announcements.dialog.form.text")}
                      name="text"
                      error={touched.text && Boolean(errors.text)}
                      helperText={touched.text && errors.text}
                      variant="outlined"
                      margin="dense"
                      multiline
                      rows={7}
                      fullWidth
                    />
                  </Grid>

                  {/* Status */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <InputLabel id="status-selection-label">
                        {i18n.t("announcements.dialog.form.status")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("announcements.dialog.form.status")}
                        placeholder={i18n.t("announcements.dialog.form.status")}
                        labelId="status-selection-label"
                        id="status"
                        name="status"
                        error={touched.status && Boolean(errors.status)}
                      >
                        <MenuItem value={true}>Ativo</MenuItem>
                        <MenuItem value={false}>Inativo</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>

                  {/* Prioridade */}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <InputLabel id="priority-selection-label">
                        {i18n.t("announcements.dialog.form.priority")}
                      </InputLabel>
                      <Field
                        as={Select}
                        label={i18n.t("announcements.dialog.form.priority")}
                        placeholder={i18n.t(
                          "announcements.dialog.form.priority"
                        )}
                        labelId="priority-selection-label"
                        id="priority"
                        name="priority"
                        error={touched.priority && Boolean(errors.priority)}
                      >
                        <MenuItem value={1}>Alta</MenuItem>
                        <MenuItem value={2}>Média</MenuItem>
                        <MenuItem value={3}>Baixa</MenuItem>
                      </Field>
                    </FormControl>
                  </Grid>

                  {/* Se existir anexo ou mediaPath, exibir */}
                  {(announcement.mediaPath || attachment) && (
                    <Grid item xs={12}>
                      <Button
                        startIcon={<AttachFileIcon />}
                        variant="outlined"
                        disabled
                        className={classes.attachmentButton}
                      >
                        {attachment ? attachment.name : announcement.mediaName}
                      </Button>
                      <IconButton
                        onClick={() => setConfirmationOpen(true)}
                        className={classes.deleteButton}
                      >
                        <DeleteOutlineIcon className={classes.deleteIcon} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions>
                {/* Botão "Anexar" só aparece se não houver nada anexado */}
                {!attachment && !announcement.mediaPath && (
                  <Button
                    color="primary"
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="outlined"
                    className={classes.attachmentButton}
                  >
                    {i18n.t("announcements.dialog.buttons.attach")}
                  </Button>
                )}

                {/* Botão Cancelar */}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.cancelButton}
                >
                  {i18n.t("announcements.dialog.buttons.cancel")}
                </Button>

                {/* Botão Salvar, com a lógica de "editar" ou "adicionar" */}
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.button} ${classes.btnWrapper}`}
                >
                  {announcementId
                    ? i18n.t("announcements.dialog.buttons.edit") 
                    : i18n.t("announcements.dialog.buttons.add")} 
                  {isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default AnnouncementModal;