// src/components/Queues/index.js

import React, { useEffect, useReducer, useState, useContext } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors"; // Importação das cores
import Box from "@mui/material/Box"; // Importação do Box
import { FiEdit, FiTrash } from "react-icons/fi";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@mui/icons-material";
import QueueModal from "../../components/QueueModal";
import toast from "../../errors/toast";
import ConfirmationModal from "../../components/ConfirmationModal";
import { SocketContext } from "../../context/Socket/SocketContext";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_QUEUES":
      const newQueues = action.payload.filter(
        (newQueue) => !state.some((queue) => queue.id === newQueue.id)
      );
      return [...state, ...newQueues];

    case "UPDATE_QUEUES":
      const updatedQueues = state.map((queue) =>
        queue.id === action.payload.id ? action.payload : queue
      );
      return updatedQueues;

    case "DELETE_QUEUE":
      return state.filter((queue) => queue.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const Queues = () => {
  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-queue`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toast.success(i18n.t("queues.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${selectedQueue.name}?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      />
      <QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />

      {/* Cabeçalho */}
      <MainHeader>
        <Box>
          <Typography
            variant="h5"
            sx={{
              color: "#6F2CC0",
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              marginBottom: 1,
            }}
          >
            {i18n.t("queues.title")}
          </Typography>
          <Typography
            sx={{
              color: "#666",
              fontSize: "0.9rem",
              fontFamily: "Inter, sans-serif",
              marginBottom: 2,
            }}
          >
            {i18n.t("queues.subtitleDescription")}
          </Typography>
        </Box>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            onClick={handleOpenQueueModal}
            sx={{
              backgroundColor: "#6F2CC0",
              color: "#fff",
              textTransform: "none",
              borderRadius: 2,
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
              "&:hover": {
                backgroundColor: "#5a21a3",
              },
            }}
          >
            Adicionar Fila
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* Tabela de Filas */}
      <Paper
        sx={{
          border: "1px solid #E3E3E3",
          borderRadius: 2, // 8px
          marginTop: 2,
          padding: 2,
          backgroundColor: "#fff",
          boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
          overflowY: "auto",
          minHeight: 600,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ccc",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
          },
        }}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#FAFAFA",
                "& th:first-child": {
                  borderTopLeftRadius: "8px",
                },
                "& th:last-child": {
                  borderTopRightRadius: "8px",
                },
              }}
            >
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.id")}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.name")}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.color")}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.orderQueue")}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.greeting")}
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold", color: "#6B6B6B", fontSize: "0.9rem", borderBottom: "none" }}>
                {i18n.t("queues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {queues.map((queue) => (
                <TableRow key={queue.id}>
                  <TableCell align="center" sx={{ fontSize: "0.875rem" }}>{queue.id}</TableCell>
                  <TableCell align="center" sx={{ fontSize: "0.875rem", fontFamily: "Inter, sans-serif" }}>{queue.name}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: queue.color,
                          width: 30,
                          height: 30,
                          borderRadius: "50%",
                          border: "1px solid #ccc",
                        }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "0.875rem", fontFamily: "Inter, sans-serif", maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {queue.orderQueue}
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "0.875rem", fontFamily: "Inter, sans-serif", maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {queue.greetingMessage}
                  </TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => handleEditQueue(queue)}
                        sx={{
                          margin: 0.5,
                          padding: "8px",
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <FiEdit style={{ color: "#000" }} /> {/* Cor preta */}
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedQueue(queue);
                          setConfirmModalOpen(true);
                        }}
                        sx={{
                          margin: 0.5,
                          padding: "8px",
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <FiTrash style={{ color: "#E03D3D" }} /> {/* Cor vermelha */}
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={6} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Queues;