import React, { useEffect, useState, useContext } from "react";
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";

import {
  Dialog,
  DialogContent,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"; 
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { SocketContext } from "../../context/Socket/SocketContext";
import PropTypes from 'prop-types'; 
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "23px",
    padding: theme.spacing(4),
    backgroundColor: "#fff",
    maxWidth: "800px",
    width: "100%",
    overflowX: "hidden", 
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap", 
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  instructions: {
    marginRight: theme.spacing(4),
    fontFamily: "Montserrat, sans-serif",
    color: "#333",
    flex: "1 1 300px", 
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: theme.spacing(2),
    color: "#6F2CC0",
    fontFamily: "Montserrat, sans-serif",
    wordBreak: "break-word", 
  },
  step: {
    fontSize: "16px",
    marginBottom: theme.spacing(2), 
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    wordBreak: "break-word", 
  },
  icon: {
    marginLeft: theme.spacing(0.08), // Reduz o espaço à esquerda do ícone
    marginRight: theme.spacing(0.08), // Ajuste a margem à direita para reduzir a distância
    width: "20px",
    height: "20px",
    fill: "#6F2CC0",
    flexShrink: 0, 
    verticalAlign: "middle", 
  },
  qrCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1 200px", 
    maxWidth: "100%", 
    textAlign: "center", 
    marginTop: theme.spacing(2), 
  },
  qrCode: {
    borderRadius: "12px",
    padding: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#f9f9f9",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  waitingText: {
    fontFamily: "Montserrat, sans-serif",
    color: "#666",
    fontSize: "16px",
    textAlign: "center",
    width: "100%", 
  },
  instructionWithIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1), 
  },
  instructionText: {
    marginRight: theme.spacing(0.05), // Diminui o espaço entre o texto e o ícone
  },
}));

const QrcodeModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const [qrCode, setQrCode] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); 

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`/whatsapp/${whatsAppId}`);
        setQrCode(data.qrcode);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  useEffect(() => {
    if (!whatsAppId) return;
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update" && data.session.id === whatsAppId) {
        setQrCode(data.session.qrcode);
      }

      if (data.action === "update" && data.session.qrcode === "") {
        onClose();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [whatsAppId, onClose, socketManager]);

  const qrSize = isSmallScreen ? 200 : 256;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        className: classes.dialogPaper, 
      }}
    >
      <DialogContent>
        <div className={classes.contentContainer}>
          <div className={classes.instructions}>
            <Typography className={classes.title}>
              Utilize o WhatsNeed com seu WhatsApp:
            </Typography>
            <Typography className={classes.step}>
              1 - Abra o WhatsApp no seu celular
            </Typography>
            <Typography className={classes.step}>
              <div className={classes.instructionWithIcon}>
                <span className={classes.instructionText}>
                  2 - Toque em Mais opções no Android
                </span>
                <svg
                  className={classes.icon}
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                </svg>
              </div>
            </Typography>
            <Typography className={classes.step}>
            <div className={classes.instructionWithIcon}>
                <span className={classes.instructionText}>
                       2.1 - Configurações no iPhone
                </span>
                <svg
                  className={classes.icon}
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"></path>
                </svg>
              </div>
              </Typography>
            <Typography className={classes.step}>
              3 - Toque em Dispositivos conectados e, em seguida, em Conectar
              dispositivos
            </Typography>
            <Typography className={classes.step}>
              4 - Aponte seu celular para essa tela para capturar o QR Code
            </Typography>
          </div>
          <div className={classes.qrCodeContainer}>
            {qrCode ? (
              <div className={classes.qrCode}>
                <QRCode value={qrCode} size={qrSize} />
              </div>
            ) : (
              <Typography className={classes.waitingText}>
                Aguarde o QR Code...
              </Typography>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

QrcodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  whatsAppId: PropTypes.string,
};

export default React.memo(QrcodeModal);