// src/components/QuickMessageDialog/index.js

import React, { useContext, useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n";
import { head } from "lodash";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import MessageVariablesPicker from "../MessageVariablesPicker";
import ConfirmationModal from "../ConfirmationModal";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";

const path = require('path');

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    // **Novas Personalizações**
    dialogPaper: {
        borderRadius: "13px", // Bordas arredondadas do Dialog
    },
    // Botões principais (Salvar/Adicionar)
    button: {
        borderRadius: "12px",  // Bordas arredondadas nos botões
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "500",
        textTransform: "none",
        backgroundColor: "#6F2CC0",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#5a21a3",
        },
    },
    // Botão de Cancelar
    cancelButton: {
		borderRadius: "8px", // Mantido
		color: theme.palette.secondary.main,
		borderRadius: "12px",  // Bordas arredondadas nos botões
		padding: "8px 16px",
		fontSize: "14px",
		fontWeight: "500",
		textTransform: "none",
	},
    // Botão de Excluir
    deleteButton: {
        backgroundColor: "F44336", // Torna o fundo transparente
        color: "#transparent", // Cor do texto (vermelho para exclusão)
        border: "none", // Sem borda
        borderRadius: "50%", // Bordas arredondadas para um formato circular
        padding: "5px", // Ajuste o padding para um botão menor
        "&:hover": {
            backgroundColor: "rgba(244, 67, 54, 0.1)", // Efeito de hover com uma leve sombra vermelha
        },
    },
    // Ícone de Excluir
    deleteIcon: {
        fontSize: "20px", // Reduz o tamanho do ícone da lixeira
        color: "#F44336", // Cor do ícone
    },
    // Botão de Anexar
    attachmentButton: {
        borderRadius: "12px", // Bordas arredondadas nos botões
        padding: "8px 16px",
        fontSize: "14px",
        fontWeight: "500",
        textTransform: "none",
        backgroundColor: "#fff",
        color: "#000", // Cor do texto
        border: `1px solid #ccc`, // Borda semelhante ao TagModal
        "&:hover": {
            backgroundColor: "#f0f0f0", // Cor de fundo ao passar o mouse
        },
        "&:focus": {
            outline: "none", // Remove o contorno de foco roxo
            border: "1px solid #6F2CC0", // Caso queira personalizar a borda quando em foco
        },
    },
    textField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "13px", // Bordas arredondadas internas
        },
    },
}));

const QuickeMessageSchema = Yup.object().shape({
    shortcode: Yup.string().required("Obrigatório"),
    //   message: Yup.string().required("Obrigatório"),
});

const QuickMessageDialog = ({ open, onClose, quickemessageId, reload }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const messageInputRef = useRef();

    const initialState = {
        shortcode: "",
        message: "",
        geral: false,
        status: true,
    };

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [quickemessage, setQuickemessage] = useState(initialState);
    const [attachment, setAttachment] = useState(null);
    const attachmentFile = useRef(null);

    useEffect(() => {
        try {
            (async () => {
                if (!quickemessageId) return;

                const { data } = await api.get(`/quick-messages/${quickemessageId}`);

                setQuickemessage((prevState) => {
                    return { ...prevState, ...data };
                });
            })();
        } catch (err) {
            toastError(err);
        }
    }, [quickemessageId, open]);

    const handleClose = () => {
        setQuickemessage(initialState);
        setAttachment(null);
        onClose();
    };

    const handleAttachmentFile = (e) => {
        const file = head(e.target.files);
        if (file) {
            setAttachment(file);
        }
    };

    const handleSaveQuickeMessage = async (values) => {
        const quickemessageData = {
            ...values,
            isMedia: true,
            mediaPath: attachment
                ? String(attachment.name).replace(/ /g, "_")
                : values.mediaPath
                    ? path.basename(values.mediaPath).replace(/ /g, "_")
                    : null
        };

        try {
            if (quickemessageId) {
                await api.put(`/quick-messages/${quickemessageId}`, quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append("typeArch", "quickMessage");
                    formData.append("file", attachment);
                    await api.post(
                        `/quick-messages/${quickemessageId}/media-upload`,
                        formData
                    );
                }
            } else {
                const { data } = await api.post("/quick-messages", quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append("typeArch", "quickMessage");
                    formData.append("file", attachment);
                    await api.post(`/quick-messages/${data.id}/media-upload`, formData);
                }
            }
            toast.success(i18n.t("quickMessages.toasts.edittoast"));
            if (typeof reload == "function") {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    const deleteMedia = async () => {
        if (attachment) {
            setAttachment(null);
            attachmentFile.current.value = null;
        }

        if (quickemessage.mediaPath) {
            await api.delete(`/quick-messages/${quickemessage.id}/media-upload`);
            setQuickemessage((prev) => ({
                ...prev,
                mediaPath: null,
            }));
            toast.success(i18n.t("quickMessages.toasts.deleteMedia"));
            if (typeof reload == "function") {
                reload();
            }
        }
    };

    const handleClickMsgVar = async (msgVar, setValueFunc) => {
        const el = messageInputRef.current;
        const firstHalfText = el.value.substring(0, el.selectionStart);
        const secondHalfText = el.value.substring(el.selectionEnd);
        const newCursorPos = el.selectionStart + msgVar.length;

        setValueFunc("message", `${firstHalfText}${msgVar}${secondHalfText}`);

        await new Promise(r => setTimeout(r, 100));
        messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t("quickMessages.confirmationModal.deleteTitle")}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={deleteMedia}
            >
                {i18n.t("quickMessages.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle id="form-dialog-title">
                    {quickemessageId
                        ? `${i18n.t("quickMessages.dialog.edit")}`
                        : `${i18n.t("quickMessages.dialog.add")}`}
                </DialogTitle>
                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        ref={attachmentFile}
                        onChange={(e) => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
                    initialValues={quickemessage}
                    enableReinitialize={true}
                    validationSchema={QuickeMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQuickeMessage(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            autoFocus
                                            label={i18n.t("quickMessages.dialog.shortcode")}
                                            name="shortcode"
                                            error={touched.shortcode && Boolean(errors.shortcode)}
                                            helperText={touched.shortcode && errors.shortcode}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            className={classes.textField}
                                        />
                                    </Grid>
                                    {(profile === "admin") && (
                                        <Grid item xs={12}>
                                            <FormControl
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="geral-selection-label">
                                                    {i18n.t("quickMessages.dialog.geral")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    label={i18n.t("quickMessages.dialog.geral")}
                                                    placeholder={i18n.t("quickMessages.dialog.geral")}
                                                    labelId="geral-selection-label"
                                                    id="geral"
                                                    name="geral"
                                                    error={touched.geral && Boolean(errors.geral)}
                                                    className={classes.textField}
                                                >
                                                    <MenuItem value={true}>Ativo</MenuItem>
                                                    <MenuItem value={false}>Inativo</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t("quickMessages.dialog.message")}
                                            name="message"
                                            inputRef={messageInputRef}
                                            error={touched.message && Boolean(errors.message)}
                                            helperText={touched.message && errors.message}
                                            variant="outlined"
                                            margin="dense"
                                            multiline={true}
                                            rows={7}
                                            fullWidth
                                            className={classes.textField}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MessageVariablesPicker
                                            disabled={isSubmitting}
                                            onClick={value => handleClickMsgVar(value, setFieldValue)}
                                        />
                                    </Grid>
                                    {(quickemessage.mediaPath || attachment) && (
                                        <Grid item xs={12} container alignItems="center">
                                            <Button
                                                startIcon={<AttachFileIcon />}
                                                className={classes.attachmentButton}
                                                variant="outlined"
                                                disabled
                                            >
                                                {attachment ? attachment.name : quickemessage.mediaName}
                                            </Button>
                                            <IconButton
                                                onClick={() => setConfirmationOpen(true)}
                                                color="secondary"
                                                className={classes.deleteButton} // Aplicando classe deleteButton
                                            >
                                                <DeleteOutlineIcon className={classes.deleteIcon} />
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {/* Botão de Anexar */}
                                {!attachment && !quickemessage.mediaPath && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => attachmentFile.current.click()}
                                        disabled={isSubmitting}
                                        className={classes.attachmentButton}
                                    >
                                        {i18n.t("quickMessages.buttons.attach")}
                                    </Button>
                                )}
                                {/* Botão de Cancelar */}
                               
                                <Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.cancelButton}
									size="medium"
								>
									{i18n.t("quickMessages.buttons.cancel")}
								</Button>
                                {/* Botão de Salvar/Adicionar */}
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.button}
                                >
                                    {quickemessageId
                                        ? `${i18n.t("quickMessages.buttons.edit")}`
                                        : `${i18n.t("quickMessages.buttons.add")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default QuickMessageDialog;