import { toast } from "react-toastify"; // Importa o toast do react-toastify
import { i18n } from "../translate/i18n"; // Para traduções
import { isString } from "lodash"; // Para verificar se a mensagem é string
import { FaExclamationTriangle } from "react-icons/fa"; // Ícone de alerta

import React from "react";

const toastWarn = (message) => {
  const toastOptions = {
    autoClose: 5000, // Tempo de exibição
    hideProgressBar: false, // Barra de progresso visível
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light", // Tema claro
    style: {
      backgroundColor: "#FF9800", // Cor de fundo laranja
      color: "#fff", // Cor do texto branca
      fontWeight: "normal", // Texto sem negrito
      borderRadius: "8px", // Bordas arredondadas
      fontSize: "14px", // Tamanho da fonte
      padding: "8px 20px", // Espaçamento mais compacto
      display: "flex", // Usar flexbox para alinhar o ícone e o texto
      alignItems: "center", // Alinhar o ícone e o texto verticalmente
    },
    icon: <FaExclamationTriangle style={{ marginRight: "10px", fontSize: "20px", color: "#fff" }} />, // Ícone de alerta
  };

  // Se for uma mensagem direta
  if (isString(message)) {
    toast.warn(message, toastOptions);
  } else {
    // Caso contrário, utiliza o texto do backend ou uma mensagem padrão
    const messageFromBackend = message?.response?.data?.message || i18n.t("warn.genericMessage");
    toast.warn(messageFromBackend, toastOptions);
  }
};

export default toastWarn;  // Exporta para ser usado em outros arquivos