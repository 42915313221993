// src/pages/Connections/Connections.js

import React, { useState, useCallback, useContext } from "react";
import { format, parseISO } from "date-fns";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import Box from "@material-ui/core/Box";

import toast from "../../errors/toast";
import { FiEdit, FiTrash } from "react-icons/fi";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import ConfirmationModal from "../../components/ConfirmationModal";
import WhatsAppModal from "../../components/WhatsAppModal";
import QrcodeModal from "../../components/QrcodeModal";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    border: "1px solid #E3E3E3", // Cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles,
  },
  headerTitle: {
    fontWeight: "bold",
    color: "#6F2CC0",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.6rem",
    margin: 0,
  },
  headerSubtitle: {
    color: "#666",
    fontSize: "0.9rem",
    marginBottom: theme.spacing(2),
    fontFamily: "Inter, sans-serif",
  },
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },
  restartButton: {
    backgroundColor: "#FFF",
    color: "#6F2CC0",
    textTransform: "none",
    borderRadius: 8,
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    border: `1px solid #6F2CC0`,
    "&:hover": {
      backgroundColor: "#EFE9F8",
    },
  },
  tableHeadCell: {
    fontWeight: "bold",
    color: "#6B6B6B",
    textAlign: "center",
    backgroundColor: "#F9F9F9", // Cor de fundo conforme Announcements
    fontFamily: "Inter, sans-serif", // Fonte conforme Announcements
    fontSize: "0.9rem",
  },
  tableCell: {
    textAlign: "center",
    color: "#333",
    fontSize: "0.9rem",
    padding: theme.spacing(1, 2),
    fontFamily: "Inter, sans-serif", // Fonte conforme Announcements
  },
  statusBadge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px", // Bordas arredondadas reduzidas
    padding: "2px 8px", // Reduzido o padding conforme Announcements
    fontSize: "0.75rem", // Tamanho da fonte conforme Announcements
    fontWeight: 600, // Peso da fonte conforme Announcements
    textTransform: "capitalize", // Texto com capitalização
    gap: "6px", // Espaçamento entre o ponto e o texto
    fontFamily: "Inter, sans-serif", // Fonte conforme Announcements
  },
  statusConnected: {
    backgroundColor: "#E6FFE6", // Fundo verde claro
    color: "#228B22", // Texto verde escuro
  },
  statusDisconnected: {
    backgroundColor: "#FFE6E6", // Fundo vermelho claro
    color: "#D32F2F", // Texto vermelho escuro
  },
  statusDot: {
    width: "8px", // Tamanho do ponto
    height: "8px", // Tamanho do ponto
    borderRadius: "50%", // Formato circular
  },
  statusDotConnected: {
    backgroundColor: "#228B22", // Cor do ponto conectado
  },
  statusDotDisconnected: {
    backgroundColor: "#D32F2F", // Cor do ponto desconectado
  },
  disconnectButton: {
    backgroundColor: "#E63946", // Vermelho principal
    color: "#FFFFFF", // Cor do texto branca
    fontSize: "0.875rem", // Tamanho do texto ajustado
    fontWeight: "normal", // Sem negrito para combinar com a imagem
    textTransform: "none", // Remove as letras maiúsculas automáticas
    borderRadius: "8px", // Arredondamento do botão
    padding: "6px 16px", // Espaçamento interno para manter o formato
    border: "none", // Remove a borda do botão
    boxShadow: "none", // Sem sombra
    "&:hover": {
      backgroundColor: "#C62828", // Vermelho mais escuro no hover
    },
    margin: theme.spacing(0, 0.5),
    fontFamily: "Inter, sans-serif", // Fonte conforme Announcements
  },
  connectButton: {
    backgroundColor: grey[500], // Cor cinza
    color: "#FFFFFF",
    fontSize: "0.875rem",
    fontWeight: "normal",
    textTransform: "none",
    borderRadius: "8px",
    padding: "6px 27px",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: grey[700],
    },
    margin: theme.spacing(0, 0.5),
    fontFamily: "Inter, sans-serif", // Fonte conforme Announcements
  },
  editIcon: {
    color: "#000", // Preto para o ícone de edição
    fontSize: "18px", // Tamanho igual ao Announcements
  },
  deleteIcon: {
    color: "#E03D3D", // Vermelho para o ícone de remoção
    fontSize: "18px", // Tamanho igual ao Announcements
  },
  actionButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionButton: {
    margin: theme.spacing(0, 0.25),
    padding: "8px", // Ajuste o padding se necessário
    backgroundColor: "transparent", // Garante que não há fundo
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)", // Apenas leve destaque no hover
    },
  },
}));

const Connections = () => {
  const classes = useStyles();
  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false); // Estado para o QR Code
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
  });

  // Função para abrir o modal de QR Code
  const handleOpenQrModal = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  // Função para fechar o modal de QR Code
  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, []);

  const handleAddWhatsApp = () => {
    setWhatsAppModalOpen(true);
  };

  const handleRestartConnections = async () => {
    try {
      await api.post(`/whatsapp-restart`);
      toast.info("Reiniciando todas as conexões...");
      // Comentado para testar sem atualizar
      // await refreshWhatsApps(); // Atualiza as conexões após reiniciar
    } catch (err) {
      toastError(err);
    }
  };

  const handleEditWhatsApp = (whatsApp) => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsApp) => {
    setConfirmModalInfo({
      action,
      title: action === "delete" ? "Excluir Conexão" : "Desconectar Conexão",
      message:
        action === "delete"
          ? `Deseja realmente excluir a conexão "${whatsApp.name}"?`
          : `Deseja desconectar a conexão "${whatsApp.name}"?`,
      whatsAppId: whatsApp.id,
    });
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    const { action, whatsAppId } = confirmModalInfo;
    try {
      if (action === "disconnect") {
        await api.delete(`/whatsappsession/${whatsAppId}`);
        toast.success("Conexão desconectada com sucesso!");
      } else if (action === "delete") {
        await api.delete(`/whatsapp/${whatsAppId}`);
        toast.success("Conexão excluída com sucesso!");
      }
      // Comentado para testar sem atualizar
      // Atualiza as conexões após a ação principal
      // try {
      //   await refreshWhatsApps();
      // } catch (refreshError) {
      //   toastError(refreshError);
      // }
    } catch (err) {
      toastError(err);
    }
    setConfirmModalOpen(false);
  };

  const formatPhoneNumber = (number) => {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    }
    return number;
  };

  // Função para iniciar a sessão do WhatsApp
  const handleStartWhatsAppSession = async (whatsAppId) => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
      toast.success("Sessão iniciada com sucesso!");
      // Comentado para testar sem atualizar
      // const whatsApp = whatsApps.find((w) => w.id === whatsAppId);
      // if (whatsApp) {
      //   setSelectedWhatsApp(whatsApp);
      //   setQrModalOpen(true); // Abrir o modal do QR Code após iniciar a sessão
      // }
      // Atualiza as conexões após iniciar a sessão
      // try {
      //   await refreshWhatsApps();
      // } catch (refreshError) {
      //   toastError(refreshError);
      // }
    } catch (err) {
      toastError(err);
    }
  };

  // Função para solicitar um novo QR Code
  const handleRequestNewQrCode = async (whatsAppId) => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
      toast.success("Novo QR Code solicitado!");
      // Comentado para testar sem atualizar
      // Atualiza as conexões após solicitar novo QR Code
      // try {
      //   await refreshWhatsApps();
      // } catch (refreshError) {
      //   toastError(refreshError);
      // }
    } catch (err) {
      toastError(err);
    }
  };

  // Função para renderizar os botões de ação
  const renderActionButtons = (whatsApp) => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="outlined"
            className={classes.connectButton}
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            Conectar
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <Button
            size="small"
            variant="outlined"
            className={classes.connectButton}
            onClick={() => handleStartWhatsAppSession(whatsApp.id)}
          >
            Conectar
          </Button>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
            <Button
              size="small"
              variant="contained"
              className={classes.disconnectButton}
              onClick={() => {
                handleOpenConfirmationModal("disconnect", whatsApp);
              }}
            >
              Desconectar
            </Button>
          )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} color="primary" />
          // Ou, se preferir centralizar:
          // <Box display="flex" justifyContent="center" alignItems="center">
          //   <CircularProgress size={24} color="primary" />
          // </Box>
        )}
      </>
    );
  };

  // Função para renderizar o status sem ações
  const renderStatus = (whatsApp) => {
    return (
      <span
        className={`${classes.statusBadge} ${
          whatsApp.status === "CONNECTED"
            ? classes.statusConnected
            : classes.statusDisconnected
        }`}
      >
        <span
          className={`${classes.statusDot} ${
            whatsApp.status === "CONNECTED"
              ? classes.statusDotConnected
              : classes.statusDotDisconnected
          }`}
        ></span>
        {whatsApp.status === "CONNECTED" ? "Conectado" : "Desconectado"}
      </span>
    );
  };

  return (
    <MainContainer>
      {/* Modais */}
      <WhatsAppModal
        open={whatsAppModalOpen}
        onClose={() => setWhatsAppModalOpen(false)}
        whatsAppId={selectedWhatsApp?.id}
      />
      <QrcodeModal
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={selectedWhatsApp?.id}
      />
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>

      {/* Header */}
      <MainHeader>
        <div>
          <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("connections.title")}
          </Typography>
          <Typography className={classes.headerSubtitle}>
            {i18n.t("connections.subtitleDescription")}
          </Typography>
        </div>

        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={handleAddWhatsApp}
          >
            + Adicionar WhatsApp
          </Button>
          <Button
            variant="outlined"
            className={classes.restartButton}
            onClick={handleRestartConnections}
          >
            Reiniciar Conexões
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* Tabela */}
      <Paper className={classes.mainPaper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>Nome</TableCell>
              <TableCell className={classes.tableHeadCell}>Número</TableCell>
              <TableCell className={classes.tableHeadCell}>Status</TableCell>
              <TableCell className={classes.tableHeadCell}>Sessão</TableCell>
              <TableCell className={classes.tableHeadCell}>
                Última Atualização
              </TableCell>
              <TableCell className={classes.tableHeadCell}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : whatsApps.length > 0 ? (
              whatsApps.map((whatsApp) => (
                <TableRow key={whatsApp.id}>
                  <TableCell className={classes.tableCell}>
                    {whatsApp.name}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatPhoneNumber(whatsApp.number)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {renderStatus(whatsApp)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {renderActionButtons(whatsApp)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {format(parseISO(whatsApp.updatedAt), "dd/MM/yyyy HH:mm")}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.actionButtons}>
                      <IconButton
                        onClick={() => handleEditWhatsApp(whatsApp)}
                        className={classes.actionButton}
                      >
                        <FiEdit className={classes.editIcon} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleOpenConfirmationModal("delete", whatsApp)
                        }
                        className={classes.actionButton}
                      >
                        <FiTrash className={classes.deleteIcon} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nenhuma conexão encontrada.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Connections;