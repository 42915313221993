import React, { useState, useEffect } from "react";
import toast from "../../errors/toast";
import {
  TextField,
  InputAdornment,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/Info";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import MainContainer from "../../components/MainContainer";
import Title from "../../components/Title";

const UserRatings = () => {
  const [loading, setLoading] = useState(false);
  const [userRatings, setUserRatings] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [selectedFeedbacks, setSelectedFeedbacks] = useState([]); // Dados do modal
  const [modalOpen, setModalOpen] = useState(false); // Estado do modal

  // Função para buscar os feedbacks
  useEffect(() => {
    setLoading(true);
    const fetchUserRatings = async () => {
      try {
        const { data } = await api.get("/feedbacks/");
        setUserRatings(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchUserRatings();
  }, []);

  // Função para filtrar os feedbacks com base no searchParam
  const handleSearchChange = (e) => {
    setSearchParam(e.target.value);
  };

  // Função para exportar feedbacks para PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const columns = ["Atendente", "Total de Atendimentos", "Média de Classificação", "Feedbacks Positivos", "Feedbacks Negativos"];
    const rows = userRatings
      .filter((rating) =>
        rating.userName.toLowerCase().includes(searchParam.toLowerCase()) || rating.userId.toString().includes(searchParam)
      )
      .map((rating) => [
        rating.userName,
        rating.feedbackCount,
        rating.averageRate,
        rating.positiveFeedbackCount,
        rating.negativeFeedbackCount,
      ]);
    doc.autoTable(columns, rows);
    doc.save("feedbacks.pdf");
  };

  // Função para abrir o modal e buscar detalhes
  const handleActionClick = async (userId) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/allfeedbacks/${userId}`); // Chama a nova API com o iduser
      setSelectedFeedbacks(data); // Armazena os dados no estado do modal
      setModalOpen(true); // Abre o modal
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  // Fecha o modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <MainContainer>
      <Paper style={{ padding: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
          <Title>{"Feedbacks"}</Title>

          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={exportToPDF}>
            {"Exportar PDF"}
          </Button>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Atendente</TableCell>
              <TableCell>Total de Atendimentos</TableCell>
              <TableCell>Média de Classificação</TableCell>
              <TableCell>Feedbacks Positivos</TableCell>
              <TableCell>Feedbacks Negativos</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userRatings
              .filter((rating) =>
                rating.userName.toLowerCase().includes(searchParam.toLowerCase()) || rating.userId.toString().includes(searchParam)
              )
              .map((rating) => (
                <TableRow key={rating.id}>
                  <TableCell>
                    <Avatar src="/nopicture.png" />
                  </TableCell>
                  <TableCell>{rating.userName}</TableCell>
                  <TableCell>{rating.feedbackCount}</TableCell>
                  <TableCell>{rating.averageRate}</TableCell>
                  <TableCell>{rating.positiveFeedbackCount}</TableCell>
                  <TableCell>{rating.negativeFeedbackCount}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleActionClick(rating.userId)}>
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {loading && <TableRowSkeleton />}
          </TableBody>
        </Table>
      </Paper>

      {/* Modal para exibir detalhes */}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Detalhes dos Atendimentos</DialogTitle>
        <DialogContent>
          {selectedFeedbacks.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Avaliação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFeedbacks.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <TableCell>{feedback.id}</TableCell>
                    <TableCell>{new Date(feedback.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{feedback.rate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p>Nenhum atendimento encontrado.</p>
          )}
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default UserRatings;
