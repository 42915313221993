import { toast as originalToast } from "react-toastify"; // Importando o toast original do react-toastify
import toastSuccess from "./toastSuccess";  // Importando o toast personalizado de sucesso
import toastInfo from "./toastInfo";  // Importando o toast personalizado de info
import toastWarn from "./toastWarn";  // Importando o toast personalizado de aviso

// Sobrescreve os métodos do toast globalmente
const toast = {
  success: toastSuccess,  // Substitui o toast.success com a versão personalizada
  error: originalToast.error,  // Mantém o toast.error original
  info: toastInfo,        // Substitui o toast.info com a versão personalizada
  warn: toastWarn,        // Substitui o toast.warn com a versão personalizada
};

export default toast;  // Exporta a versão personalizada de toast para ser usada globalmente