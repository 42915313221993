import React, { useState, useEffect } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { makeStyles, Paper, Tabs, Tab } from "@material-ui/core";

import TabPanel from "../../components/TabPanel";

import SchedulesForm from "../../components/SchedulesForm";
import CompaniesManager from "../../components/CompaniesManager";
import PlansManager from "../../components/PlansManager";
import HelpsManager from "../../components/HelpsManager";
import Options from "../../components/Settings/Options";
import Uploader from "../../components/Settings/Uploader";
import NewCompaniesManager from "../../pages/Companies";

// Correção da importação do Typography
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n.js";
import { toast, ToastContainer } from "react-toastify";

import useCompanies from "../../hooks/useCompanies";
import useAuth from "../../hooks/useAuth.js";
import useSettings from "../../hooks/useSettings";

import OnlyForSuperUser from "../../components/OnlyForSuperUser";

// Importação do CSS do react-toastify
import 'react-toastify/dist/ReactToastify.css';

// Estilização com makeStyles para consistência com o componente Options e Quickemessages
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Definindo a fonte global
  },
  mainPaper: {
    ...theme.scrollbarStyles,
    overflowY: "auto", // Melhor UX
    flex: 1,
    borderRadius: 8, // Reduzido de '13px' para '8px' para harmonizar com Quickemessages
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)", // Ajustado para uma sombra mais sutil
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    backgroundColor: "#fff",
    border: "1px solid #E3E3E3", // Adicionado para harmonizar com Quickemessages
    position: 'relative',
  },
  tabContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: "#fff", // Definido para consistência
    zIndex: 1000,
    boxShadow: "0 3px 10px rgba(0,0,0,0.4)", // Atualize aqui para uma sombra mais forte
    borderRadius: 8, // Reduzido de '13px' para '8px'
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center', // Centraliza a barra de Tabs horizontalmente
    padding: theme.spacing(1, 2), // Adicionado padding
  },
  tabs: {
    maxWidth: '1200px',
    width: '100%',
  },
  headerTitle: {
    color: "#6F2CC0",       // Cor personalizada do título
    fontWeight: "bold",     // Peso da fonte (negrito)
    margin: 0,
    fontSize: "1.5rem",     // Aumentado para melhor visibilidade
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tab: {
    minWidth: 120, // Define uma largura mínima para cada Tab
    flexGrow: 1, // Permite que as Tabs cresçam igualmente
    textAlign: 'center', // Centraliza o texto dentro de cada Tab
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "1rem", // Consistente com títulos
    color: "#6B6B6B", // Cor consistente
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      fontWeight: "bold", // Destaca a aba selecionada
    },
  },
  paper: {
    ...theme.scrollbarStyles,
    overflowY: "auto",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    borderRadius: 8, // Reduzido para harmonizar
    backgroundColor: "#fff",
    border: "1px solid #E3E3E3", // Adicionado para harmonizar
  },
  container: {
    width: "100%",
    maxHeight: "100%",
  },
  control: {
    padding: theme.spacing(1),
  },
  textfield: {
    width: "100%",
    fontSize: "1rem", // Consistente com outras fontes
  },
}));

const SettingsCustom = () => {
  const classes = useStyles();
  const [tab, setTab] = useState("options");
  const [schedules, setSchedules] = useState([]);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [settings, setSettings] = useState({});
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const { getCurrentUserInfo } = useAuth();
  const { find, updateSchedules } = useCompanies();
  const { getAll: getAllSettings } = useSettings();

  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("companyId");
        const company = await find(companyId);
        const settingList = await getAllSettings();
        setCompany(company);
        setSchedules(company.schedules);
        setSettings(settingList);

        if (Array.isArray(settingList)) {
          const scheduleType = settingList.find(
            (d) => d.key === "scheduleType"
          );
          if (scheduleType) {
            setSchedulesEnabled(scheduleType.value === "company");
          }
        }

        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e.message || 'Erro ao buscar dados.');
      }
      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newValue) => {
    async function findData() {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("companyId");
        const company = await find(companyId);
        const settingList = await getAllSettings();
        setCompany(company);
        setSchedules(company.schedules);
        setSettings(settingList);

        if (Array.isArray(settingList)) {
          const scheduleType = settingList.find(
            (d) => d.key === "scheduleType"
          );
          if (scheduleType) {
            setSchedulesEnabled(scheduleType.value === "company");
          }
        }

        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e.message || 'Erro ao buscar dados.');
      }
      setLoading(false);
    }
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    setTab(newValue);
  };

  const handleSubmitSchedules = async (data) => {
    setLoading(true);
    try {
      setSchedules(data);
      await updateSchedules({ id: company.id, schedules: data });
      toast.success("Horários atualizados com sucesso.");
    } catch (e) {
      toast.error(e.message || "Erro ao atualizar horários.");
    }
    setLoading(false);
  };

  const isSuper = () => {
    return currentUser.super;
  };

  return (
    <MainContainer className={classes.root}>
      {/* ToastContainer colocado aqui */}
      <ToastContainer />
      <MainHeader>
        {/* Removido o <div> que envolvia o Typography */}
        <Typography variant="h5" className={classes.headerTitle}>
          {i18n.t("settings.title")}
        </Typography>
      </MainHeader>
      <Paper className={classes.mainPaper} elevation={3}>
        {/* Barra de Tabs Flutuante */}
        <Paper className={classes.tabContainer} elevation={0}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="on"
            variant="scrollable"
            onChange={handleTabChange}
            className={classes.tabs}
          >
            <Tab label="Opções" value={"options"} className={classes.tab} />
            {schedulesEnabled && (
              <Tab label="Horários" value={"schedules"} className={classes.tab} />
            )}
            {isSuper() && (
              <Tab label="Logo" value={"uploader"} className={classes.tab} />
            )}
            {isSuper() && (
              <Tab label="Empresas" value={"companies"} className={classes.tab} />
            )}
            {isSuper() && (
              <Tab label="Planos" value={"plans"} className={classes.tab} />
            )}
            {isSuper() && (
              <Tab label="Ajuda" value={"helps"} className={classes.tab} />
            )}
          </Tabs>
        </Paper>

        {/* Conteúdo das Abas */}
        <Paper className={classes.paper} elevation={0}>
          <TabPanel
            className={classes.container}
            value={tab}
            name={"schedules"}
          >
            <SchedulesForm
              loading={loading}
              onSubmit={handleSubmitSchedules}
              initialValues={schedules}
            />
          </TabPanel>
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                name={"companies"}
              >
                <CompaniesManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                name={"newcompanie"}
              >
                <NewCompaniesManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                name={"plans"}
              >
                <PlansManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                name={"helps"}
              >
                <HelpsManager />
              </TabPanel>
            )}
          />
          <OnlyForSuperUser
            user={currentUser}
            yes={() => (
              <TabPanel
                className={classes.container}
                value={tab}
                name={"uploader"}
              >
                <Uploader />
              </TabPanel>
            )}
          />
          <TabPanel className={classes.container} value={tab} name={"options"}>
            <Options
              settings={settings}
              scheduleTypeChanged={(value) =>
                setSchedulesEnabled(value === "company")
              }
            />
          </TabPanel>
        </Paper>
      </Paper>
    </MainContainer>
  );
};

export default SettingsCustom;