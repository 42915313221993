import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef
} from "react";

// Material-UI (v4) e estilos
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  Typography
} from "@material-ui/core";

// Ícones
import SearchIcon from "@material-ui/icons/Search";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { FiEdit, FiTrash } from "react-icons/fi";


// React-Toastify / CSV
import { CSVLink } from "react-csv";
import toast from "../../errors/toast";
import { useHistory } from "react-router-dom";

// Componentes internos
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactModal from "../../components/ContactModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import NewTicketModal from "../../components/NewTicketModal";
import { SocketContext } from "../../context/Socket/SocketContext";
import { generateColor } from "../../helpers/colorGenerator";
import { getInitials } from "../../helpers/getInitials";

// ==========================
// 1. REDUCER (INALTERADO)
// ==========================
const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_CONTACTS":
      const newContacts = [];
      action.payload.forEach((contact) => {
        const index = state.findIndex((c) => c.id === contact.id);
        if (index !== -1) {
          state[index] = contact;
        } else {
          newContacts.push(contact);
        }
      });
      return [...state, ...newContacts];

    case "UPDATE_CONTACTS":
      const contact = action.payload;
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
        return [...state];
      } else {
        return [contact, ...state];
      }

    case "DELETE_CONTACT":
      return state.filter((c) => c.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

// ==========================
// 2. ESTILOS (BASEADO NO QUICKEMESSAGES)
// ==========================
const useStyles = makeStyles((theme) => ({
  // ====== Título, fontes, etc. ======
  headerTitle: {
    color: "#6F2CC0",
    fontWeight: "bold",
    margin: 0
  },

  // (Se quiser um subtítulo, igual no Quickemessages)
  headerSubtitle: {
    margin: 0,
    color: "#8E8E8E",
    fontSize: "0.95rem",
    fontFamily: "Inter, sans-serif"
  },

  // ====== Layout Principal ======
  mainContainer: {
    fontFamily: "Inter, sans-serif"
  },
  mainHeader: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },

  // ====== Título (reaproveita Title) ======
  title: {
    color: "#6F2CC0",
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    fontSize: "1.5rem",
    marginBottom: theme.spacing(1),
  },

  // ====== Botões do Header ======
  headerButtonsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  addButton: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: "#5a21a3"
    }
  },

  // ====== Campo de busca (search) ======
  searchInput: {
    backgroundColor: "#fff",
    borderRadius: 8,
    width: 220,
    marginRight: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d8d8d8"
    },
    fontFamily: "Inter, sans-serif"
  },

  // ====== Paper / Card da Tabela ======
  mainPaper: {
    border: "1px solid #E3E3E3", // cor do contorno
    borderRadius: 8,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.15)",
    overflowY: "auto",
    minHeight: 600,
    ...theme.scrollbarStyles
  },

  // ====== Cabeçalho da Tabela ======
  tableHeadRow: {
    backgroundColor: "#FAFAFA",
    "& th:first-child": {
      borderTopLeftRadius: 8
    },
    "& th:last-child": {
      borderTopRightRadius: 8
    }
  },
  tableCellHead: {
    color: "#6B6B6B",
    fontWeight: "bold",
    borderBottom: "none",
    fontSize: "0.9rem",
    fontFamily: "Inter, sans-serif"
  },

  // ====== Corpo da Tabela ======
  tableRow: {
    "&:hover": {
      backgroundColor: "#FAFAFA"
    }
  },
  tableCellBody: {
    borderBottom: "1px solid #F0F0F0",
    fontSize: "0.875rem",
    fontFamily: "Inter, sans-serif"
  },

  // Ações de edição/remoção
  actionButton: {
    margin: theme.spacing(0, 0.5)
  },
  editIcon: {
    color: "#000"
  },
  deleteIcon: {
    color: "#E03D3D"
  },

  // ====== Campo de busca alternativo (se quiser) ======
  searchField: {
    backgroundColor: "#fff",
    borderRadius: 8,
    fontFamily: "Inter, sans-serif",
    "& .MuiInputBase-root": {
      borderRadius: 8,
      color: "#333",
    }
  },

  // ====== Botão roxo genérico ======
  buttonPurple: {
    backgroundColor: "#6F2CC0",
    color: "#fff",
    textTransform: "none",
    borderRadius: 8,
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },

  // ====== Pílulas de status (Ativo/Inativo) ======
  activePill: {
    backgroundColor: "#E6FFE6",
    color: "#2DB53B",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Inter, sans-serif"
  },
  inactivePill: {
    backgroundColor: "#FFE6E6",
    color: "#E03D3D",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Inter, sans-serif"
  },
  unknownPill: {
    backgroundColor: "#F0F0F0",
    color: "#777",
    borderRadius: 12,
    padding: "2px 8px",
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Inter, sans-serif"
  },
}));

// Componente para renderizar pílula
const StatusPill = ({ isActive }) => {
  const classes = useStyles();

  if (isActive === true) {
    return <span className={classes.activePill}>Ativo</span>;
  } else if (isActive === false) {
    return <span className={classes.inactivePill}>Inativo</span>;
  } else {
    return <span className={classes.unknownPill}>Desconhecido</span>;
  }
};

// ==========================
// 3. COMPONENTE PRINCIPAL
// ==========================
const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const fileUploadRef = useRef(null);
  const socketManager = useContext(SocketContext);

  // Reseta lista ao mudar busca
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  // Busca com debounce
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  // Socket (tempo real)
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  // Funções de modal e busca
  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };
  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  // Excluir
  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  // Importar planilha
  const handleimportContact = async () => {
    try {
      if (fileUploadRef.current?.files[0]) {
        const formData = new FormData();
        formData.append("file", fileUploadRef.current.files[0]);
        await api.request({
          url: `/contacts/upload`,
          method: "POST",
          data: formData,
        });
      } else {
        await api.post("/contacts/import");
      }
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  // Exibir data da última mensagem
  function getDateLastMessage(contact) {
    if (!contact) return null;
    if (!contact.tickets) return null;
    if (contact.tickets.length > 0) {
      const date = new Date(contact.tickets[contact.tickets.length - 1].updatedAt);

      const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      const month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      const year = date.getFullYear().toString().slice(-2);

      const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
      const minutes =
        date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
    return null;
  }

  // Paginação
  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  // RENDER
  return (
    <MainContainer className={classes.mainContainer}>
      {/* Modal para novo ticket */}
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => handleCloseOrOpenTicket(ticket)}
      />

      {/* Modal de contato */}
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />

      {/* Modal de confirmação (excluir ou importar) */}
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${deletingContact.name
            }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? i18n.t("contacts.confirmationModal.deleteMessage")
          : i18n.t("contacts.confirmationModal.importMessage")}
      </ConfirmationModal>

      {/* HEADER */}
      <MainHeader className={classes.mainHeader}>
      <Typography variant="h5" className={classes.headerTitle}>
            {i18n.t("contacts.title")}
          </Typography>

        <MainHeaderButtonsWrapper className={classes.headerButtonsWrapper}>
          {/* Campo de busca no estilo Quickemessages */}
          <TextField
            variant="outlined"
            size="small"
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => setConfirmOpen(true)}
          >
            {i18n.t("contacts.buttons.import")}
          </Button>

          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => {
              fileUploadRef.current.value = null;
              fileUploadRef.current.click();
            }}
          >
            {i18n.t("contacts.buttons.importSheet")}
          </Button>

          <Button
            variant="contained"
            className={classes.addButton}
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>

          <CSVLink
            style={{ textDecoration: "none" }}
            separator=";"
            filename="Contatos.csv"
            data={contacts.map((c) => ({
              name: c.name,
              number: c.number,
              email: c.email,
            }))}
          >
            <Button variant="contained" className={classes.addButton}>
              Exportar contatos
            </Button>
          </CSVLink>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      {/* INPUT "invisível" para upload de planilha */}
      <input
        style={{ display: "none" }}
        id="upload"
        name="file"
        type="file"
        accept=".xls,.xlsx"
        onChange={() => setConfirmOpen(true)}
        ref={fileUploadRef}
      />

      {/* TABELA */}
      <Paper className={classes.mainPaper} onScroll={handleScroll}>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell
                padding="checkbox"
                className={classes.tableCellHead}
              />
              <TableCell className={classes.tableCellHead}>
                {i18n.t("contacts.table.name")}
              </TableCell>
              <TableCell align="center" className={classes.tableCellHead}>
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center" className={classes.tableCellHead}>
                {i18n.t("contacts.table.email")}
              </TableCell>
              <TableCell align="center" className={classes.tableCellHead}>
                {"Última Interação"}
              </TableCell>
              <TableCell align="center" className={classes.tableCellHead}>
                {"Status"}
              </TableCell>
              <TableCell align="center" className={classes.tableCellHead}>
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {contacts.map((contact) => (
              <TableRow key={contact.id} className={classes.tableRow}>
                <TableCell className={classes.tableCellBody}>
                  <Avatar
                    style={{
                      backgroundColor: generateColor(contact?.number),
                      fontWeight: "bold",
                      color: "white",
                    }}
                    src={contact.profilePicUrl}
                  >
                    {getInitials(contact?.name)}
                  </Avatar>
                </TableCell>

                <TableCell className={classes.tableCellBody}>
                  {contact.name}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.tableCellBody}
                >
                  {contact.number}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.tableCellBody}
                >
                  {contact.email}
                </TableCell>

                <TableCell
                  align="center"
                  className={classes.tableCellBody}
                >
                  {getDateLastMessage(contact)}
                </TableCell>

                {/* Pílula de status (Ativo/Inativo) */}
                <TableCell align="center" className={classes.tableCellBody}>
                  <StatusPill isActive={contact.active} />
                </TableCell>

                {/* Ações: WhatsApp, Editar, Excluir */}
                <TableCell align="center" className={classes.tableCellBody}>
                  <IconButton
                    size="small"
                    className={classes.actionButton}
                    onClick={() => {
                      setContactTicket(contact);
                      setNewTicketModalOpen(true);
                    }}
                  >
                    <WhatsAppIcon className={classes.editIcon} />
                  </IconButton>

                  <IconButton
                    size="small"
                    className={classes.actionButton}
                    onClick={() => hadleEditContact(contact.id)}
                  >
                    <FiEdit className={classes.editIcon} size={20} />
                  </IconButton>

                  <Can
                    role={user.profile}
                    perform="contacts-page:deleteContact"
                    yes={() => (
                      <IconButton
                        size="small"
                        className={classes.actionButton}
                        onClick={() => {
                          setConfirmOpen(true);
                          setDeletingContact(contact);
                        }}
                      >
                        <FiTrash className={classes.deleteIcon} size={20} />
                      </IconButton>
                    )}
                  />
                </TableCell>
              </TableRow>
            ))}

            {loading && <TableRowSkeleton avatar columns={5} />}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;