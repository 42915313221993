import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "../../errors/toast";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress,
  Grid
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Montserrat, sans-serif", // Mesma fonte
  },
  dialogPaper: {
    borderRadius: "13px", // Bordas arredondadas
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    maxWidth: "450px",
    width: "100%",
    overflowX: "hidden",
  },
  textField: {
    flex: 1,
    margin: theme.spacing(1, 0), // Espaçamento vertical
    "& .MuiOutlinedInput-root": {
      borderRadius: 6, // Bordas arredondadas
      padding: 0,
    },
    "& .MuiInputLabel-outlined": {
      fontSize: "16px", // Tamanho de fonte aumentado
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 16px", // Espaçamento interno
      fontSize: "16px", // Tamanho de fonte aumentado
    },
    "& .MuiFormHelperText-root": {
      fontSize: "14px", // Tamanho de fonte da ajuda
    },
  },
  btnWrapper: {
    position: "relative",
  },
  submitButton: {
    borderRadius: "12px", 
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
    backgroundColor: "#6F2CC0",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#5a21a3",
    },
  },
  cancelButton: {
    borderRadius: "12px", 
    color: theme.palette.secondary.main,
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    textTransform: "none",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    name: "",
    number: "",
    email: "",
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async (values) => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
      }
      toast.success(i18n.t("contactModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        scroll="paper"
        PaperProps={{ className: classes.dialogPaper }}
      >
        <DialogTitle id="form-dialog-title">
          {contactId
            ? i18n.t("contactModal.title.edit")
            : i18n.t("contactModal.title.add")}
        </DialogTitle>
        <Formik
          initialValues={contact}
          enableReinitialize
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Typography variant="subtitle1" gutterBottom>
                  {i18n.t("contactModal.form.mainInfo")}
                </Typography>

                {/* Organizando campos com Grid */}
                <Grid container spacing={2}>
                  {/* Nome */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.name")}
                      name="name"
                      autoFocus
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  </Grid>

                  {/* Número */}
                  <Grid item xs={12} sm={6}>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.number")}
                      name="number"
                      error={touched.number && Boolean(errors.number)}
                      helperText={touched.number && errors.number}
                      placeholder="5541998608485"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                    />
                  </Grid>

                  {/* E-mail */}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      placeholder="Email address"
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {/* Exemplo de texto adicional (WhatsApp) */}
                <Typography
                  style={{ marginBottom: 8, marginTop: 12 }}
                  variant="subtitle1"
                >
                  {i18n.t("contactModal.form.whatsapp")}{" "}
                  {contact?.whatsapp ? contact.whatsapp.name : ""}
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                  className={classes.cancelButton}
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={`${classes.submitButton} ${classes.btnWrapper}`}
                >
                  {contactId
                    ? i18n.t("contactModal.buttons.okEdit")
                    : i18n.t("contactModal.buttons.okAdd")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ContactModal;