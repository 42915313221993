import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt"; // Do primeiro código
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'; // Do segundo código
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined'; // Presente em ambos
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined"; // Do primeiro código
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'; // Do segundo código
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'; // Braço robo
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined'; // Do segundo código
import FlashOnIcon from "@material-ui/icons/FlashOn";
import DynamicFormIcon from '@mui/icons-material/DynamicForm'; // Do segundo código
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'; // Do segundo código
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined'; // Do segundo código
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'; // Presente em ambos
import ListIcon from "@material-ui/icons/ListAlt";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'; // Do segundo código
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined'; // Do segundo código
import ForumIcon from "@material-ui/icons/Forum";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'; // Do segundo código
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined'; // Do segundo código
import RotateRight from "@material-ui/icons/RotateRight";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import LoyaltyRoundedIcon from "@material-ui/icons/LoyaltyRounded";
import { Can } from "../components/Can";
import { SocketContext } from "../context/Socket/SocketContext";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import TableChartIcon from "@material-ui/icons/TableChart";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard'; // Do segundo código
import api from "../services/api";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'; // Do segundo código
import ToDoList from "../pages/ToDoList/";
import toastError from "../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import { AllInclusive, AttachFile, BlurCircular, Description, DeviceHubOutlined, Schedule } from '@material-ui/icons';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined'; // Do segundo código
import WebhookOutlinedIcon from '@mui/icons-material/WebhookOutlined'; // Do segundo código
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined'; // Do segundo código
import usePlans from "../hooks/usePlans";
import Typography from "@material-ui/core/Typography";
import useVersion from "../hooks/useVersion";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

// ALTERAR ICONES ACCORDION
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// ICONE FEEDBACK
import FeedbackIcon from '@mui/icons-material/Feedback';

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
  logoutButton: {
    borderRadius: 10,
    marginTop: 10,
    backgroundColor: theme.palette.sair.main,
    color: theme.palette.text.sair,
  },
}));


function ListItemLink(props) {
  const { icon, primary, to, className } = props;
  const location = useLocation(); // Hook para obter a rota ativa

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  // Verifica se a rota está ativa
  const isActive = location.pathname === to;

  return (
    <li>
      <ListItem
        button
        dense
        component={renderLink}
        className={className}
        style={{
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: isActive ? "rgb(162 1 255 / 10%)" : "transparent", // Cor de fundo condicional
          color: "black", // Cor do texto condicional
          transition: "background-color 0.3s ease", // Transição suave para o hover
        }}
        onMouseEnter={(e) => {
          // Altera a cor do fundo no hover
          if (!isActive) {
            e.currentTarget.style.backgroundColor = "#f5f5f5";
          }
        }}
        onMouseLeave={(e) => {
          // Restaura a cor original ao sair do hover
          if (!isActive) {
            e.currentTarget.style.backgroundColor = "transparent";
          }
        }}
      >
        {icon ? (
          <ListItemIcon>
            {React.cloneElement(icon, { style: { color: isActive ? "#6F2CC0" : "black" } })}
          </ListItemIcon>
        ) : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}


const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [Feedbacks, setFeedbacks] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false); const history = useHistory();
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);


  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();

  const [version, setVersion] = useState(false);


  const { getVersion } = useVersion();

  const socketManager = useContext(SocketContext);

  useEffect(() => {
    async function fetchVersion() {
      const _version = await getVersion();
      setVersion(_version.version);
    }
    fetchVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setFeedbacks(planConfigs.plan.useFeedbacks);

      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [socketManager]);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  return (
    <div onClick={drawerClose}>
      <Can
        role={user.profile}
        perform={"drawer-service-items:view"}
        style={{
          overflowY: "scroll",
        }}
        no={() => (
          <>
            <Accordion defaultExpanded style={{ boxShadow: 'none' }}>

              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header" aria-controls="panel-content" style={{ height: '0px' }}>
                <ListSubheader
                  hidden={collapsed}
                  style={{
                    fontSize: "17px",
                    paddingLeft: 1
                  }}
                  inset
                  color="inherit">
                  {i18n.t("Atendimento")}
                </ListSubheader>
                <SupportAgentIcon hidden={collapsed} style={{
                  color: 'black',
                  display: !collapsed ? 'none' : 'block' // Oculta o ícone quando colapsado
                }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <ListItemLink
                  to="/tickets"
                  primary={i18n.t("mainDrawer.listItems.tickets")}
                  icon={<WhatsAppIcon style={{ color: "black" }} />}
                />
                <ListItemLink
                  to="/quick-messages"
                  primary={i18n.t("mainDrawer.listItems.quickMessages")}
                  icon={<DynamicFormIcon style={{ color: 'black' }} />}
                />
                {showKanban && (
                  <ListItemLink
                    to="/kanban"
                    primary="Kanban"
                    icon={<SpaceDashboardIcon style={{ color: 'black' }} />}
                  />
                )}
                <ListItemLink
                  to="/todolist"
                  primary={i18n.t("Tarefas")}
                  icon={<PendingActionsOutlinedIcon style={{ color: 'black' }} />}
                />
                {showSchedules && (
                  <ListItemLink
                    to="/schedules"
                    primary={i18n.t("mainDrawer.listItems.schedules")}
                    icon={<CalendarMonthOutlinedIcon style={{ color: 'black' }} />}
                  />
                )}
                <ListItemLink
                  to="/tags"
                  primary={i18n.t("mainDrawer.listItems.tags")}
                  icon={<DiscountOutlinedIcon style={{ color: 'black' }} />}
                />
                {showInternalChat && (
                  <ListItemLink
                    to="/chats"
                    primary={i18n.t("mainDrawer.listItems.chats")}
                    icon={
                      <Badge color="secondary" variant="dot" invisible={invisible}>
                        <ForumOutlinedIcon style={{ color: 'black' }} />
                      </Badge>
                    }
                  />
                )}
                <ListItemLink
                  to="/helps"
                  primary={i18n.t("mainDrawer.listItems.helps")}
                  icon={<HelpOutlineIcon style={{ color: 'black' }} />}
                />
              </AccordionDetails>
            </Accordion>
          </>
        )}
      />
      <Can
        role={user.profile}
        perform={"drawer-admin-items:view"}
        yes={() => (
          <>
            <Accordion style={{ boxShadow: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header" aria-controls="panel-content" style={{ height: '0px' }}>
                <ListSubheader
                  hidden={collapsed}
                  style={{
                    fontSize: "17px",
                    paddingLeft: 1
                  }}
                  inset
                  color="inherit"
                >
                  {i18n.t("Gerência")}
                </ListSubheader>
                <ManageAccountsIcon hidden={collapsed} style={{
                  color: 'black',
                  display: !collapsed ? 'none' : 'block' // Oculta o ícone quando colapsado
                }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                <ListItemLink
                  small
                  to="/"
                  primary="Dashboard"
                  icon={<DashboardOutlinedIcon style={{ color: 'black' }} />}
                />
              </AccordionDetails>
              {(Feedbacks || user.super) && (
                  <ListItemLink
                    to="/feedbacks"
                    primary={"Feedbacks"}
                    icon={<FeedbackIcon style={{ color: 'black' }} />}
                  />
                )}
            </Accordion>
          </>
        )}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            {showCampaigns && (
              <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header" aria-controls="panel-content" style={{ height: '0px' }}>
                  <ListSubheader
                    hidden={collapsed}
                    style={{
                      fontSize: "17px",
                      paddingLeft: 1
                    }}
                    inset
                    color="inherit"
                  >
                    {i18n.t("Disparo")}
                  </ListSubheader>
                  <ScheduleSendIcon hidden={collapsed} style={{
                    color: 'black',
                    display: !collapsed ? 'none' : 'block' // Oculta o ícone quando colapsado
                  }} />
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>

                  <ListItemLink
                    to="/campaigns"
                    primary={"Listagem"}
                    icon={<WysiwygOutlinedIcon style={{ color: 'black' }} />}
                  />
                  <ListItemLink
                    to="/contact-lists"
                    primary={"Lista de Contatos"}
                    icon={<PermIdentityOutlinedIcon style={{ color: 'black' }} />}
                  />
                  <ListItemLink
                    to="/campaigns-config"
                    primary={"Configurações"}
                    icon={<FingerprintOutlinedIcon style={{ color: 'black' }} />}
                  />

                </AccordionDetails>
              </Accordion>
            )}

            <Accordion style={{ boxShadow: 'none' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel-header" aria-controls="panel-content" style={{ height: '0px' }}>
                <ListSubheader
                  hidden={collapsed}
                  style={{
                    fontSize: "17px",
                    paddingLeft: 1
                  }}
                  inset
                  color="inherit"
                >
                    {i18n.t("Administração")}

                </ListSubheader>
                <AdminPanelSettingsIcon hidden={collapsed} style={{
                  color: 'black',
                  display: !collapsed ? 'none' : 'block' // Oculta o ícone quando colapsado
                }} />
              </AccordionSummary>
              <AccordionDetails style={{ padding: 0 }}>
                {user.super && (
                  <ListItemLink
                    to="/announcements"
                    primary={i18n.t("mainDrawer.listItems.annoucements")}
                    icon={<ReportGmailerrorredOutlinedIcon style={{ color: 'black' }} />}
                  />
                )}
                {showOpenAi && (
                  <ListItemLink
                    to="/prompts"
                    primary={i18n.t("mainDrawer.listItems.prompts")}
                    icon={<WebhookOutlinedIcon style={{ color: 'black' }} />}
                  />
                )}
                {showIntegrations && (
                  <ListItemLink
                    to="/queue-integration"
                    primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                    icon={<PolylineOutlinedIcon style={{ color: 'black' }} />}
                  />
                )}
                <ListItemLink
                  to="/typebot"
                  primary={i18n.t("Typebot")}
                  icon={
                    <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                      <PrecisionManufacturingIcon style={{ color: 'black' }} />
                    </Badge>
                  }
                />
                <ListItemLink
                  to="/connections"
                  primary={i18n.t("mainDrawer.listItems.connections")}
                  icon={
                    <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                      <SwapHorizOutlinedIcon style={{ color: 'black' }} />
                    </Badge>
                  }
                />
                <ListItemLink
                  to="/files"
                  primary={i18n.t("mainDrawer.listItems.files")}
                  icon={<CloudOutlinedIcon style={{ color: 'black' }} />}
                />
                <ListItemLink
                  to="/queues"
                  primary={i18n.t("mainDrawer.listItems.queues")}
                  icon={<ViewAgendaOutlinedIcon style={{ color: 'black' }} />}
                />
                <ListItemLink
                  to="/users"
                  primary={i18n.t("mainDrawer.listItems.users")}
                  icon={<GroupsOutlinedIcon style={{ color: 'black' }} />}
                />
                {showExternalApi && (
                  <ListItemLink
                    to="/messages-api"
                    primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                    icon={<CodeRoundedIcon style={{ color: 'black' }} />}
                  />
                )}
                <ListItemLink
                  to="/contacts"
                  primary={i18n.t("mainDrawer.listItems.contacts")}
                  icon={<PeopleAltOutlinedIcon style={{ color: 'black' }} />}
                />
                <ListItemLink
                  to="/financeiro"
                  primary={i18n.t("mainDrawer.listItems.financeiro")}
                  icon={<RequestPageOutlinedIcon style={{ color: 'black' }} />}
                />
                <ListItemLink
                  to="/settings"
                  primary={i18n.t("mainDrawer.listItems.settings")}
                  icon={<FingerprintOutlinedIcon style={{ color: 'black' }} />}
                />
              </AccordionDetails>
            </Accordion>
            {/* {!collapsed && (
              <React.Fragment>
                <Divider />
                <Typography style={{ fontSize: "12px", padding: "10px", textAlign: "right", fontWeight: "bold" }}>
                  Versão: 1.0
                </Typography>
              </React.Fragment>
            )} */}
          </>
        )}
      />
       <Divider />
      {/*<li>
        <ListItem
          button
          dense
          onClick={handleClickLogout}
          className={classes.logoutButton}
        >
          <ListItemIcon>
            <RotateRight />
          </ListItemIcon>
          <ListItemText primary={i18n.t("Sair")} />
        </ListItem>
      </li> */}
    </div>
  );

};

export default MainListItems;
